import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Spinner, Alert } from 'react-bootstrap';
import Chart from 'chart.js/auto';

const BiVendas: React.FC = () => {
  const [vendas, setVendas] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstanceRef = useRef<Chart<'pie', number[], string> | null>(null);

  useEffect(() => {
    fetchTopVendas();
  }, []);

  useEffect(() => {
    if (!loading && !error && chartRef.current) {
      // Destruir o gráfico anterior, se houver
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        chartInstanceRef.current = new Chart(ctx, {
          type: 'pie', // Altere o tipo de gráfico para 'pie'
          data: {
            labels: vendas.map((venda) => `Venda ${venda.id_venda}`),
            datasets: [
              {
                label: 'Total Pago (BRL)',
                data: vendas.map((venda) =>
                  parseFloat(venda.total_paid_amount)
                ),
                backgroundColor: vendas.map(
                  () =>
                    // Gerando uma cor aleatória para cada venda
                    `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
                      Math.random() * 255
                    )}, ${Math.floor(Math.random() * 255)}, 0.6)`
                ),
                borderColor: vendas.map(() => 'rgba(255, 255, 255, 1)'),
                borderWidth: 1,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: true,
                text: '5 Maiores Vendas',
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    const vendaIndex = context.dataIndex;
                    const venda = vendas[vendaIndex];
                    const descricao =
                      venda.descricao || 'Pedido sem produto identificado';
                    return `${descricao}: R$ ${(context.raw as number).toFixed(
                      2
                    )}`;
                  },
                },
              },
            },
          },
        });
      }
    }
  }, [loading, error, vendas]);

  const fetchTopVendas = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/bi_vendasMax`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('authToken')}`,
          },
        }
      );

      setVendas(response.data.data);
    } catch (error) {
      console.error('Erro ao buscar as 5 maiores vendas:', error);
      setError('Erro ao buscar as 5 maiores vendas.');
    } finally {
      setLoading(false);
    }
  };

  // Destruir o gráfico ao desmontar o componente
  useEffect(() => {
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, []);

  return (
    <div>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only"></span>
          </Spinner>
        </div>
      ) : error ? (
        <Alert variant="danger" className="text-center">
          {error}
        </Alert>
      ) : (
        <div style={{ height: '390px' }}>
          <canvas ref={chartRef}></canvas>
        </div>
      )}
    </div>
  );
};

export default BiVendas;
