import React, { useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import BarcodeCanvas from './components/BarcodeCanvas';

interface Product {
  id: number;
  name: string;
  code: string;
}

const fakeData: Product[] = [
  {
    id: 1,
    name: 'Produto 1',
    code: '123456789012',
  },
  {
    id: 2,
    name: 'Produto 2',
    code: '987654321098',
  },
  {
    id: 3,
    name: 'Produto 3',
    code: '123459876543',
  },
];

const BarcodeLabel: React.FC<{ product: Product }> = ({ product }) => (
  <div className="label">
    <div>{product.name}</div>
    <div className="barcode">
      <BarcodeCanvas value={product.code} />
    </div>
    <div>{product.code}</div>
  </div>
);

const Ean: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [printType, setPrintType] = useState<string>('pdf');

  useEffect(() => {
    setProducts(fakeData);
  }, []);

  const handlePrint = () => {
    if (printType === 'pdf') {
      const doc = new jsPDF();
      products.forEach((product, index) => {
        const canvas = document.getElementById(
          `barcode-${product.code}`
        ) as HTMLCanvasElement;
        if (canvas) {
          const dataURL = canvas.toDataURL('image/png');
          doc.text(product.name, 20, 20 + index * 30);
          doc.addImage(dataURL, 'PNG', 10, 20 + index * 30, 50, 20);
        }
      });
      doc.save('etiquetas.pdf');
    } else if (printType === 'zebra') {
      printZebraLabels();
    } else if (printType === 'argox') {
      printArgoxLabels();
    } else {
      window.print();
    }
  };

  const printZebraLabels = () => {
    const zebraCommands = products
      .map(
        (product) => `
      ^XA
      ^FO50,50^A0N,50,50^FD${product.name}^FS
      ^FO50,150^BY2^BCN,100,Y,N,N^FD${product.code}^FS
      ^XZ
    `
      )
      .join('\n');
    sendToPrinter(zebraCommands);
  };

  const printArgoxLabels = () => {
    const argoxCommands = products
      .map(
        (product) => `
      ! 0 200 200 400 1
      TEXT 4 0 30 40 ${product.name}
      BARCODE 128 1 1 50 30 100 ${product.code}
      PRINT
    `
      )
      .join('\n');
    sendToPrinter(argoxCommands);
  };

  const sendToPrinter = (commands: string) => {
    // Implement the logic to send commands to the printer
    console.log('Sending commands to printer:', commands);
  };

  return (
    <div>
      <div>
        <label htmlFor="printType">Selecionar Tipo de Impressão: </label>
        <select
          id="printType"
          value={printType}
          onChange={(e) => setPrintType(e.target.value)}
        >
          <option value="pdf">PDF</option>
          <option value="zebra">Zebra</option>
          <option value="argox">Argox</option>
          <option value="normal">Normal</option>
        </select>
      </div>
      <button onClick={handlePrint}>Imprimir Etiquetas</button>
      <div className="barcode-container">
        {products.map((product) => (
          <BarcodeLabel key={product.id} product={product} />
        ))}
      </div>
    </div>
  );
};

export default Ean;
