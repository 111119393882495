import React from 'react';
import { Pagination } from 'react-bootstrap';

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (pageNumber: number) => void;
}

const CustomPagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
    const pageNeighbours = 2; // Número de páginas para mostrar ao lado da página atual

    const getPageNumbers = () => {
        const totalNumbers = pageNeighbours * 2 + 1;
        const totalBlocks = totalNumbers + 2;

        if (totalPages > totalBlocks) {
            const startPage = Math.max(2, currentPage - pageNeighbours);
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

            let pages: (number | string)[] = range(startPage, endPage);

            if (startPage > 2) {
                pages = ['...', ...pages];
            }

            if (endPage < totalPages - 1) {
                pages = [...pages, '...'];
            }

            return [1, ...pages, totalPages];
        }

        return range(1, totalPages);
    };

    const range = (from: number, to: number, step = 1) => {
        let i = from;
        const range = [];

        while (i <= to) {
            range.push(i);
            i += step;
        }

        return range;
    };

    return (
        <Pagination className="justify-content-center mt-4">
            <Pagination.First
                onClick={() => onPageChange(1)}
                disabled={currentPage === 1}
            />
            <Pagination.Prev
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
            />
            {getPageNumbers().map((page, index) =>
                page === '...' ? (
                    <Pagination.Ellipsis key={index} disabled />
                ) : (
                    <Pagination.Item
                        key={index}
                        active={page === currentPage}
                        onClick={() => onPageChange(Number(page))}
                    >
                        {page}
                    </Pagination.Item>
                )
            )}
            <Pagination.Next
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
            />
            <Pagination.Last
                onClick={() => onPageChange(totalPages)}
                disabled={currentPage === totalPages}
            />
        </Pagination>
    );
};

export default CustomPagination;
