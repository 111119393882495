import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Product, ApiResponseData } from '../../components/types';
import {
  Button,
  Table,
  Spinner,
  InputGroup,
  FormControl,
  FormSelect,
  Modal,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faFileExcel,
  faFilePdf,
  faEdit,
  faTrashAlt,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import ProductDetails from './components/ProductDetails';
import LocalProductDetails from './components/LocalProductDetails';
import { refreshToken } from '../../components/Token/refreshToken';
import {
  PermissionWrapper,
  PermissionButton,
  PermissionTable,
} from '../../components/Permission';

const fetchProducts = async (
  page: number,
  perPage: number,
  search: string
): Promise<ApiResponseData<Product[]>> => {
  const token = sessionStorage.getItem('authToken');
  if (!token) {
    throw new Error('Token não encontrado. Por favor, faça login novamente.');
  }

  let url = `${
    import.meta.env.VITE_APP_API_URL
  }/produtos?page=${page}&per_page=${perPage}`;
  if (search) {
    url += `&search=${search}`;
  }

  const response = await axios.get<ApiResponseData<Product[]>>(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

const Produtos: React.FC = () => {
  const [search, setSearch] = React.useState<string>('');
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [perPage, setPerPage] = React.useState<number>(5);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState<number | null>(
    null
  );
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const navigate = useNavigate();

  const {
    data: apiData,
    isLoading,
    refetch,
  } = useQuery<ApiResponseData<Product[]>, Error>({
    queryKey: ['products', currentPage, perPage, search],
    queryFn: () => fetchProducts(currentPage, perPage, search),
    staleTime: 5000,
    refetchOnWindowFocus: false,
  });

  const products = apiData?.data ?? [];
  const totalPages = apiData?.meta?.last_page ?? 1;
  const total = apiData?.meta?.total ?? 0;
  const to = apiData?.meta?.to ?? 0;

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSearchKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      refetch();
    }
  };

  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handlePerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleCreateProduct = () => {
    navigate('/produtos/cadastrar');
  };

  const handleEditProduct = (productId: number) => {
    navigate(`/produtos/editar/${productId}`);
  };

  const handleDeleteProduct = async () => {
    if (selectedProductId === null) return;

    const token = sessionStorage.getItem('authToken');
    if (!token) {
      toast.error('Token não encontrado. Por favor, faça login novamente.');
      return;
    }

    try {
      // Tenta verificar se há uma compra associada ao produto e obtém o compra_id
      try {
        const comprasResponse = await axios.get(
          `${
            import.meta.env.VITE_APP_API_URL
          }/compras/produto/${selectedProductId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const compras = comprasResponse.data;

        if (compras.length > 0) {
          // Se houver compras associadas ao produto
          let message =
            'Não foi possível excluir o produto porque ele está associado às seguintes compras:\n';
          compras.forEach((compra: any) => {
            message += `- Nota Fiscal: ${compra.nota_fiscal}, Compra ID: ${compra.id}\n`;
          });
          toast.error(message);
          // Retorna para parar o processo de exclusão do produto
          return;
        }
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          console.log(
            'Nenhuma compra associada ao produto foi encontrada. Continuando...'
          );
          // Continua com o próximo passo, mesmo se a requisição para compras falhar
        } else {
          console.error('Ocorreu um erro ao verificar as compras:', error);
          // Pode optar por retornar ou lidar com o erro de forma diferente
          return;
        }
      }

      // Verifica se o produto está no estoque e obtém o estoque_id
      const estoqueResponse = await axios.get(
        `${
          import.meta.env.VITE_APP_API_URL
        }/estoquelocal/hasEstoque/${selectedProductId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const estoqueId = estoqueResponse.data?.estoque_id;

      if (estoqueId) {
        // Exclui o estoque associado ao produto
        await axios.delete(
          `${import.meta.env.VITE_APP_API_URL}/estoques/${estoqueId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        toast.success('Estoque associado ao produto excluído com sucesso!');
      } else {
        console.log('Nenhum estoque associado ao produto foi encontrado.');
      }

      // Exclui o produto
      await axios.delete(
        `${import.meta.env.VITE_APP_API_URL}/produtos/${selectedProductId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success('Produto excluído com sucesso!');
    } catch (error) {
      toast.error('Erro ao excluir o produto ou o estoque.');
      console.error('Erro ao excluir produto ou estoque:', error);
    } finally {
      refetch();
      setShowDeleteModal(false);
    }
  };

  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(products);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Produtos');
    XLSX.writeFile(wb, 'produtos.xlsx');
    toast.success('Exportação para Excel realizada com sucesso!');
  };

  const exportToPDF = async () => {
    const pdf = new jsPDF();
    const title = 'Relatório de Produtos Cadastrados';
    const tableColumn = [
      'ID',
      'Descrição',
      'SKU',
      'Última Atualização',
      'Usuário',
    ];
    const tableRows: any[] = [];

    products.forEach((product: Product) => {
      const productData = [
        product.id,
        product.descricao,
        product.sku,
        new Date(product.updated_at).toLocaleDateString(),
        product.user_email,
      ];
      tableRows.push(productData);
    });

    pdf.setFontSize(18);
    pdf.text(title, 13, 8);
    pdf.setFontSize(12);
    pdf.text('Data: ' + new Date().toLocaleDateString(), 165, 8);

    autoTable(pdf, {
      head: [tableColumn],
      body: tableRows,
      startY: 10,
      theme: 'grid',
      styles: { fontSize: 8, cellPadding: 2 },
      headStyles: { fillColor: [0, 0, 0], textColor: [255, 255, 255] },
    });

    pdf.save('produtos.pdf');
    toast.success('Exportação para PDF realizada com sucesso!');
  };

  const handleShowDetails = async (product: Product) => {
    setSelectedProduct(product);

    if (product.meli_conta_id && product.meli_conta_id > 0) {
      try {
        // Usar refreshTokenWithLoading aqui
        await refreshTokenWithLoading(product.meli_conta_id.toString());
      } catch (error) {
        toast.error('Erro ao atualizar o token do Mercado Livre.');
        console.error('Erro ao atualizar o token:', error);
        return;
      }
    }

    setShowDetailsModal(true);
  };

  return (
    <>
      <PermissionWrapper screenId={'1'}>
        <PermissionButton permission="create">Cadastrar</PermissionButton>
        <PermissionButton permission="edit">Editar</PermissionButton>
        <PermissionButton permission="delete">Excluir</PermissionButton>
        <PermissionTable permission="view">
          {/* Conteúdo da tabela */}
        </PermissionTable>

        <div className="container-fluid">
          <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
            <Button
              variant="success"
              onClick={handleCreateProduct}
              className="mr-2 mb-2"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: '5px' }} />
              Cadastrar Produto
            </Button>
            <div className="d-flex flex-grow-1 align-items-center mr-2 mb-2">
              <InputGroup className="flex-grow-1">
                <FormControl
                  placeholder="Pesquisar"
                  aria-label="Pesquisar"
                  value={search}
                  onChange={handleSearchChange}
                  onKeyDown={handleSearchKeyPress}
                  className="w-100"
                  style={{
                    borderRadius: '25px',
                    padding: '10px',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                  }}
                />
              </InputGroup>
            </div>
            <div className="d-flex align-items-center">
              <Button
                variant="info"
                onClick={exportToExcel}
                className="mr-2 mb-2"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <FontAwesomeIcon
                  icon={faFileExcel}
                  style={{ marginRight: '5px' }}
                />
                Exportar para Excel
              </Button>
              <Button
                variant="warning"
                onClick={exportToPDF}
                className="mr-2 mb-2"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <FontAwesomeIcon
                  icon={faFilePdf}
                  style={{ marginRight: '5px' }}
                />
                Exportar para PDF
              </Button>
              <FormSelect
                value={perPage}
                onChange={handlePerPageChange}
                className="mb-2"
                style={{
                  width: '100px',
                  borderRadius: '25px',
                  padding: '5px',
                  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                }}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={150}>150</option>
                <option value={500}>500</option>
                <option value={1000}>1000</option>
                <option value={5000}>5000</option>
              </FormSelect>
            </div>
          </div>

          {isLoading ? (
            <div className="text-center">
              <Spinner animation="border" role="status">
                <span className="sr-only">@</span>
              </Spinner>
            </div>
          ) : (
            <>
              <div className="table-responsive">
                <Table hover className="table-striped" id="table-to-print">
                  <thead className="thead-dark table table-dark">
                    <tr>
                      <th style={{ width: '5%' }}>ID</th>
                      <th style={{ width: '30%' }}>Descrição</th>
                      <th style={{ width: '10%' }}>SKU</th>
                      <th style={{ width: '15%' }}>Id Sku Mercado Livre</th>
                      <th style={{ width: '15%' }}>Última Atualização</th>
                      <th style={{ width: '15%' }}>Usuário</th>
                      <th style={{ width: '10%' }}>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.map((product: Product) => (
                      <tr key={product.id}>
                        <td>{product.id}</td>
                        <td>{product.descricao}</td>
                        <td>{product.sku}</td>
                        <td>{product.meli_product_id}</td>
                        <td>
                          {new Date(product.updated_at).toLocaleDateString()}
                        </td>
                        <td>{product.user_email}</td>
                        <td className="d-flex justify-content-between">
                          <Button
                            variant="primary"
                            size="sm"
                            onClick={() => handleEditProduct(product.id)}
                            className="mr-1"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              borderRadius: '20px',
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faEdit}
                              style={{ marginRight: '5px' }}
                            />
                            Editar
                          </Button>
                          <Button
                            variant="info"
                            size="sm"
                            onClick={() => handleShowDetails(product)}
                            className="mr-1"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              borderRadius: '20px',
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faInfoCircle}
                              style={{ marginRight: '5px' }}
                            />
                            Detalhes
                          </Button>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => {
                              setSelectedProductId(product.id);
                              setShowDeleteModal(true);
                            }}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              borderRadius: '20px',
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faTrashAlt}
                              style={{ marginRight: '5px' }}
                            />
                            Excluir
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <br />
              <p className="text-muted">
                Mostrando {to} de {total} produtos
              </p>
              <nav>
                <ul className="pagination justify-content-center">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? 'disabled' : ''
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      Anterior
                    </button>
                  </li>
                  {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                    (pageNumber) => (
                      <li
                        key={pageNumber}
                        className={`page-item ${
                          currentPage === pageNumber ? 'active' : ''
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      </li>
                    )
                  )}
                  <li
                    className={`page-item ${
                      currentPage === totalPages ? 'disabled' : ''
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      Próxima
                    </button>
                  </li>
                </ul>
              </nav>
            </>
          )}

          {/* Modal de Exclusão */}
          <Modal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirmar Exclusão</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Tem certeza de que deseja excluir este produto?
              <strong style={{ color: 'red' }}> Atenção:</strong> A exclusão
              deste produto também removerá todo o estoque. Caso existe uma
              compra vinculado o produto não será possível excluir.
              <strong style={{ color: 'red' }}>
                {' '}
                Esta ação não pode ser desfeita.
              </strong>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancelar
              </Button>
              <Button variant="danger" onClick={handleDeleteProduct}>
                Excluir
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Modal de Detalhes */}
          <Modal
            show={showDetailsModal}
            onHide={() => setShowDetailsModal(false)}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Detalhes do Produto</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedProduct && selectedProduct.meli_product_id ? (
                <ProductDetails productId={selectedProduct.meli_product_id} />
              ) : (
                <LocalProductDetails product={selectedProduct} />
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowDetailsModal(false)}
              >
                Fechar
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </PermissionWrapper>
    </>
  );
};

export default Produtos;

export const refreshTokenWithLoading = async (
  meliContaId: string,
  setLoading: ((loading: boolean) => void) | null = null
): Promise<void> => {
  if (setLoading) {
    await refreshToken(meliContaId, setLoading);
  } else {
    await refreshToken(meliContaId, () => {});
  }
};
