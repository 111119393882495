import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './CalculoModal.css'; // Reutilizando a mesma folha de estilo

interface CalculoSugestao2ModalProps {
  show: boolean;
  handleClose: () => void;
  valorTotalPago: number;
  margemDesejada: number;
  margemAtual: number;
  precoSugestao2: number;
}

const CalculoSugestao2Modal: React.FC<CalculoSugestao2ModalProps> = ({
  show,
  handleClose,
  valorTotalPago,
  margemDesejada,
  margemAtual,
  precoSugestao2,
}) => {
  const formula = `
    Preço Sugestão = Valor Total Pago + ((Margem Desejada - Margem Atual) * Valor Total Pago)
  `;

  const formulaComValores = `
    Preço Sugestão = ${valorTotalPago} + ((${margemDesejada} - ${margemAtual}) * ${valorTotalPago})
  `;

  return (
    <Modal show={show} onHide={handleClose} dialogClassName="custom-modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>
          Detalhes do Cálculo do Preço Sugestão Venda Final
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>Fórmula:</strong>
        </p>
        <pre className="formula-box">{formula}</pre>

        <p>
          <strong>Substituindo os valores:</strong>
        </p>
        <pre className="formula-box">{formulaComValores}</pre>

        <p className="resultado-box">
          <strong>Preço Sugestão Venda Final:</strong>
          <span className="resultado-valor">
            R$ {precoSugestao2.toFixed(2)}
          </span>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CalculoSugestao2Modal;
