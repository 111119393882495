import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './CalculoModal.css';

interface CalculoModalProps {
  show: boolean;
  handleClose: () => void;
  precoCusto: number | string;
  tarifaVenda: number | string;
  custoEnvio: number | string;
  imposto: number | string;
  margemDesejada: number | string;
  porcentagemProduto: number | string;
  precoSugerido: number;
}

const CalculoModal: React.FC<CalculoModalProps> = ({
  show,
  handleClose,
  precoCusto,
  tarifaVenda,
  custoEnvio,
  imposto,
  margemDesejada,
  porcentagemProduto,
  precoSugerido,
}) => {
  const precoCustoNum = parseFloat(precoCusto as string) || 0;
  const tarifaVendaNum = parseFloat(tarifaVenda as string) || 0;
  const custoEnvioNum = parseFloat(custoEnvio as string) || 0;
  const impostoNum = parseFloat(imposto as string) / 100 || 0;
  const margemDesejadaNum = parseFloat(margemDesejada as string) / 100 || 0;
  const porcentagemProdutoNum =
    parseFloat(porcentagemProduto as string) / 100 || 0;

  const formula = `
    Preço Sugerido = (Preço de Custo + Tarifa de Venda + Custo de Envio) /
    (1 - (Imposto + Margem Desejada + Porcentagem do Produto))
  `;

  const formulaComValores = `
    Preço Sugerido = (${precoCustoNum.toFixed(2)} + ${tarifaVendaNum.toFixed(
    2
  )} + ${custoEnvioNum.toFixed(2)}) /
    (1 - (${impostoNum.toFixed(4)} + ${margemDesejadaNum.toFixed(
    2
  )} + ${porcentagemProdutoNum.toFixed(2)}))
  `;

  const denominador =
    1 - (impostoNum + margemDesejadaNum + porcentagemProdutoNum);

  return (
    <Modal show={show} onHide={handleClose} dialogClassName="custom-modal-lg">
      <Modal.Header closeButton>
        <Modal.Title className="modal-title">
          Detalhes do Cálculo do Preço Sugerido
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <p className="section-title">Fórmula:</p>
        <pre className="formula-text">{formula}</pre>

        <p className="section-title">Substituindo os valores:</p>
        <pre className="formula-text">{formulaComValores}</pre>

        <p className="section-title">Denominador calculado:</p>
        <p className="calculated-value">
          1 - ({impostoNum.toFixed(4)} + {margemDesejadaNum.toFixed(2)} +{' '}
          {porcentagemProdutoNum.toFixed(2)}) = {denominador.toFixed(4)}
        </p>

        <p className="section-title">Preço Sugerido:</p>
        <p className="calculated-price">R$ {precoSugerido.toFixed(2)}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CalculoModal;
