import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Spinner, Alert } from 'react-bootstrap';
import Chart from 'chart.js/auto';

const ProductCount: React.FC = () => {
  const [productCount, setProductCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const chartRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    fetchProductCount();
  }, []);

  useEffect(() => {
    if (!loading && !error && chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        new Chart(ctx, {
          type: 'bar',
          data: {
            labels: ['Produtos'],
            datasets: [
              {
                label: 'Quantidade de Produtos',
                data: [productCount],
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                beginAtZero: true,
                max: Math.max(productCount, 10), // Ajusta a escala máxima para melhorar a visualização
              },
            },
            plugins: {
              title: {
                display: true,
                text: 'Total de Produtos',
              },
            },
          },
        });
      }
    }
  }, [loading, error, productCount]);

  const fetchProductCount = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/produtos`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('authToken')}`,
          },
        }
      );

      setProductCount(response.data.meta.total);
    } catch (error) {
      console.error('Erro ao buscar quantidade de produtos:', error);
      setError('Erro ao buscar quantidade de produtos.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only"></span>
          </Spinner>
        </div>
      ) : error ? (
        <Alert variant="danger" className="text-center">
          {error}
        </Alert>
      ) : (
        <div style={{ width: '300px', height: '400px' }}>
          <canvas ref={chartRef}></canvas>
        </div>
      )}
    </div>
  );
};

export default ProductCount;
