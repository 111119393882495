import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import SalesForm from './SalesForm'; // Importa o componente SalesForm
import { Sale } from './SalesTable'; // Importa a interface Sale

interface SalesModalProps {
  show: boolean;
  handleClose: () => void;
  sale?: Sale;
  handleSave: (sale: Sale) => void;
}

const SalesModal: React.FC<SalesModalProps> = ({ show, handleClose, sale, handleSave }) => {
  const isEdit = !!sale;

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{isEdit ? 'Editar Venda' : 'Adicionar Venda'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SalesForm sale={sale} onSave={handleSave} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button form="sales-form" variant="primary" type="submit">
          {isEdit ? 'Salvar Alterações' : 'Adicionar Venda'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SalesModal;
