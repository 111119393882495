import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import ExcelJS from 'exceljs';
import {
  formatCurrency,
  translateCondition,
  translateStatus,
} from '../../../utils';
import { FaFileExcel } from 'react-icons/fa'; // Importando o ícone do Excel

interface ExcelExportButtonProps {
  filteredProducts: any[]; // Substitua `any` pelo tipo apropriado se disponível
  setExporting: (exporting: boolean) => void;
  exporting: boolean;
}

const ExcelExportButton: React.FC<ExcelExportButtonProps> = ({
  filteredProducts,
  setExporting,
  exporting,
}) => {
  const exportToExcel = async () => {
    setExporting(true); // Ativa o loader

    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Produtos');

      // Adiciona os cabeçalhos, incluindo todos os campos que você deseja exportar
      worksheet.columns = [
        { header: 'ID', key: 'id', width: 20 },
        { header: 'Descrição', key: 'title', width: 40 },
        { header: 'SKU', key: 'seller_sku', width: 20 },
        { header: 'Preço', key: 'price', width: 15 },
        { header: 'Preço Original', key: 'original_price', width: 15 },
        { header: 'Moeda', key: 'currency_id', width: 10 },
        { header: 'Condição', key: 'condition', width: 15 },
        { header: 'Categoria', key: 'category_name', width: 25 },
        { header: 'Quantidade Inicial', key: 'initial_quantity', width: 20 },
        {
          header: 'Quantidade Disponível',
          key: 'available_quantity',
          width: 20,
        },
        { header: 'Quantidade Vendida', key: 'sold_quantity', width: 20 },
        { header: 'Status', key: 'status', width: 15 },
        { header: 'Permalink', key: 'permalink', width: 30 },
        // Não incluir o campo 'thumbnail' pois é a imagem
      ];

      // Adiciona os dados das linhas
      filteredProducts.forEach((product) => {
        worksheet.addRow({
          id: product.id,
          title: product.title,
          seller_sku: product.seller_sku,
          price: formatCurrency(product.price ?? 0),
          original_price: formatCurrency(product.original_price ?? 0),
          currency_id: product.currency_id,
          condition: translateCondition(product.condition ?? 'Desconhecido'),
          category_name: product.category_name,
          initial_quantity: product.initial_quantity,
          available_quantity: product.available_quantity,
          sold_quantity: product.sold_quantity,
          status: translateStatus(product.status ?? 'Desconhecido'),
          permalink: product.permalink,
        });
      });

      // Estilizando cabeçalho
      worksheet.getRow(1).font = { bold: true, color: { argb: 'FFFFFF' } };
      worksheet.getRow(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '000000' },
      };

      // Gera o buffer do arquivo e faz o download
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'produtos.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      toast.success('Exportação para Excel realizada com sucesso!');
    } catch (error) {
      console.error('Erro ao exportar para Excel:', error);
      toast.error('Erro ao exportar para Excel.');
    } finally {
      setExporting(false); // Desativa o loader
    }
  };

  return (
    <Button
      onClick={exportToExcel}
      variant="info"
      className="w-100 d-flex align-items-center justify-content-center"
      disabled={filteredProducts.length === 0 || exporting} // Desativa o botão enquanto exporta
    >
      {exporting ? (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <>
          <FaFileExcel size={20} className="me-2" /> {/* Ícone do Excel */}
          Exportar Excel
        </>
      )}
    </Button>
  );
};

export default ExcelExportButton;
