import React from 'react';
import { Product } from '../../../components/types';
import { Table } from 'react-bootstrap';

interface LocalProductDetailsProps {
  product: Product | null;
}

const LocalProductDetails: React.FC<LocalProductDetailsProps> = ({
  product,
}) => {
  if (!product) return null;

  return (
    <Table striped bordered hover>
      <tbody>
        <tr>
          <td>
            <strong>ID:</strong>
          </td>
          <td>{product.id}</td>
        </tr>
        <tr>
          <td>
            <strong>Descrição:</strong>
          </td>
          <td>{product.descricao}</td>
        </tr>
        <tr>
          <td>
            <strong>SKU:</strong>
          </td>
          <td>{product.sku}</td>
        </tr>
        <tr>
          <td>
            <strong>Última Atualização:</strong>
          </td>
          <td>{new Date(product.updated_at).toLocaleDateString()}</td>
        </tr>
        <tr>
          <td>
            <strong>Usuário:</strong>
          </td>
          <td>{product.user_email}</td>
        </tr>
        <tr>
          <td>
            <strong>Quantidade Disponível:</strong>
          </td>
          <td>{product.available_quantity}</td>
        </tr>
        {/* Adicione mais detalhes conforme necessário */}
      </tbody>
    </Table>
  );
};

export default LocalProductDetails;
