import React from 'react';
import SalesTable from './components/SalesTable';

const VendasLocal: React.FC = () => {
  return (
    <div>
      <SalesTable></SalesTable>
    </div>
  );
};

export default VendasLocal;
