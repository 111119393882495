import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaChartLine, FaWarehouse } from 'react-icons/fa'; // Importando ícones

const Relatorios: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="container my-5">
      <h1
        className="mb-5 text-center"
        style={{ fontWeight: 'bold', color: '#343a40' }}
      >
        <span style={{ borderBottom: '4px solid #007bff' }}>Relatórios</span>
      </h1>
      <div className="row justify-content-center">
        <div
          className="col-12 col-md-6 col-lg-6 mb-4"
          onClick={() => navigate('/relatorios/relatoriosVenda')}
        >
          <div
            className="p-4 bg-white border rounded shadow-sm cursor-pointer h-100 d-flex flex-column align-items-center justify-content-center"
            style={{ transition: 'transform 0.2s, box-shadow 0.2s' }}
          >
            <FaChartLine size={100} color="#007bff" />
            <h2 className="mt-4" style={{ color: '#007bff' }}>
              Relatórios de Vendas
            </h2>
          </div>
        </div>
        <div
          className="col-12 col-md-6 col-lg-6 mb-4"
          onClick={() => navigate('/relatorios/relatoriosMovimentacaoEstoque')}
        >
          <div
            className="p-4 bg-white border rounded shadow-sm cursor-pointer h-100 d-flex flex-column align-items-center justify-content-center"
            style={{ transition: 'transform 0.2s, box-shadow 0.2s' }}
          >
            <FaWarehouse size={100} color="#28a745" />
            <h2 className="mt-4" style={{ color: '#28a745' }}>
              Movimentação de Estoque
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Relatorios;
