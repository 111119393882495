import React from 'react';
import ProductCount from './components/ProductCount';
import BiEstoqueMin from './components/BiEstoqueMin';
import BiEstoqueMax from './components/BiEstoqueMax';
import BiVendas from './components/BiVendas';

const Home: React.FC = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-6 col-lg-3 mb-1">
          <ProductCount />
        </div>
        <div className="col-12 col-md-6 col-lg-3 mb-1">
          <BiVendas />
        </div>
        <br />
        <div className="col-12 col-md-6 col-lg-12 mb-1">
          <hr />
        </div>
        <div className="col-12 col-md-6 col-lg-12 mb-1">
          <BiEstoqueMin />
        </div>
        <div className="col-12 col-md-6 col-lg-12 mb-1">
          <hr />
        </div>
        <br />
        <div className="col-12 col-md-6 col-lg-12 mb-1">
          <BiEstoqueMax />
        </div>
      </div>
    </div>
  );
};

export default Home;
