import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';
import { Modal, Button } from 'react-bootstrap';
import './login.css';

const ResetPassword: React.FC = () => {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const [showRulesModal, setShowRulesModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const togglePasswordVisibility = (field: 'password' | 'confirmation') => {
    if (field === 'password') {
      setShowPassword(!showPassword);
    } else {
      setShowPasswordConfirmation(!showPasswordConfirmation);
    }
  };

  const validatePassword = (value: string) => {
    return value.length >= 8;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validatePassword(password)) {
      setShowRulesModal(true);
      return;
    }

    if (password !== passwordConfirmation) {
      toast.error('As senhas não coincidem.');
      return;
    }

    setLoading(true);

    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const email = searchParams.get('email');

    if (!token || !email) {
      toast.error('Link de redefinição de senha inválido.');
      return;
    }

    try {
      await axios.post(`${import.meta.env.VITE_APP_API_URL}/reset-password`, {
        token,
        email,
        password,
        password_confirmation: passwordConfirmation,
      });

      toast.success('Senha redefinida com sucesso!');
      navigate('/login');
    } catch (error) {
      toast.error('Ocorreu um erro ao redefinir a senha. Tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2 className="text-center mb-4">Redefinir Senha</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group mb-3">
            <label htmlFor="password">Nova Senha</label>
            <div className="input-group">
              <span className="input-group-text">
                <FaLock />
              </span>
              <input
                type={showPassword ? 'text' : 'password'}
                className="form-control"
                id="password"
                placeholder="Digite sua nova senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={() => togglePasswordVisibility('password')}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </div>
          <div className="form-group mb-3">
            <label htmlFor="passwordConfirmation">Confirme a Nova Senha</label>
            <div className="input-group">
              <span className="input-group-text">
                <FaLock />
              </span>
              <input
                type={showPasswordConfirmation ? 'text' : 'password'}
                className="form-control"
                id="passwordConfirmation"
                placeholder="Confirme sua nova senha"
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                required
              />
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={() => togglePasswordVisibility('confirmation')}
              >
                {showPasswordConfirmation ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-primary btn-block w-100"
            disabled={loading}
          >
            {loading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              'Redefinir Senha'
            )}
          </button>
        </form>
      </div>

      <Modal show={showRulesModal} onHide={() => setShowRulesModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Regras de Senha</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>A senha deve ter pelo menos 8 caracteres.</p>
          <p>Por favor, escolha uma senha mais longa para maior segurança.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowRulesModal(false)}>
            Entendi
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ResetPassword;
