import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Table, Form, InputGroup, FormSelect } from 'react-bootstrap';
import { toast } from 'react-toastify';
import SalesModal from './SalesModal';
import CustomPagination from './CustomPagination';
import { formatCurrency } from '../../../utils/formatCurrency';

export interface Sale {
  id: string;
  id_venda: string;
  date_created: string;
  buyer_nickname: string;
  item_title: string;
  seller_sku: string;
  quantity: number;
  unit_price: number;
  full_unit_price: number;
  total_paid_amount: number;
  sale_fee: number;
  shipping_cost: number;
  listing_type: string;
  delivery_status: string;
  total: number;
  receiver_name: string;
  address_line: string;
  city: string;
  state: string;
  zip_code: string;
  receiver_phone: string;
  meli_conta_id: string;
}

const SalesTable: React.FC = () => {
  const [sales, setSales] = useState<Sale[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(15);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [currentSale, setCurrentSale] = useState<Sale | undefined>();

  const authToken = sessionStorage.getItem('authToken');

  useEffect(() => {
    fetchSales();
  }, [currentPage, perPage, search]);

  const fetchSales = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/sales`,
        {
          params: {
            search,
            per_page: perPage,
            page: currentPage,
          },
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setSales(response.data.data);
      setTotalPages(response.data.last_page);
    } catch (error) {
      console.error('Erro ao buscar vendas:', error);
      toast.error('Erro ao buscar vendas.');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (sale: Sale): void => {
    setCurrentSale(sale);
    setModalShow(true);
  };

  const handleDelete = async (saleId: string): Promise<void> => {
    const confirmDelete = window.confirm(
      'Tem certeza que deseja excluir esta venda?'
    );
    if (confirmDelete) {
      try {
        await axios.delete(
          `${import.meta.env.VITE_APP_API_URL}/sales/${saleId}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        toast.success('Venda excluída com sucesso!');
        fetchSales();
      } catch (error) {
        console.error('Erro ao excluir venda:', error);
        toast.error('Erro ao excluir venda.');
      }
    }
  };

  const handleSave = async (sale: Sale): Promise<void> => {
    try {
      if (sale.id) {
        // Editar
        await axios.put(
          `${import.meta.env.VITE_APP_API_URL}/sales/${sale.id}`,
          sale,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        toast.success('Venda atualizada com sucesso!');
      } else {
        // Adicionar
        await axios.post(`${import.meta.env.VITE_APP_API_URL}/sales`, sale, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        toast.success('Venda adicionada com sucesso!');
      }
      setModalShow(false);
      fetchSales();
    } catch (error) {
      console.error('Erro ao salvar venda:', error);
      toast.error('Erro ao salvar venda.');
    }
  };

  const handlePageChange = (pageNumber: number): void => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearch(e.target.value);
  };

  const handleSearchKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (e.key === 'Enter') {
      fetchSales();
    }
  };

  const handlePerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    setPerPage(Number(e.target.value));
    setCurrentPage(1); // Resetar para a primeira página ao mudar o número de itens por página
  };

  return (
    <>
      <h1 className="text-center mb-4">Vendas IntegraMeli</h1>
      <div className="d-flex justify-content-between mb-3">
        <InputGroup className="w-50">
          <Form.Control
            type="text"
            placeholder="Buscar vendas..."
            value={search}
            onChange={handleSearchChange}
            onKeyPress={handleSearchKeyPress}
          />
          <Button variant="outline-secondary" onClick={fetchSales}>
            Buscar
          </Button>
        </InputGroup>
        <FormSelect
          value={perPage}
          onChange={handlePerPageChange}
          className="mb-2"
          style={{ width: '100px' }}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={150}>150</option>
          <option value={500}>500</option>
          <option value={1000}>1000</option>
          <option value={5000}>5000</option>
        </FormSelect>
        <Button
          variant="primary"
          onClick={() => {
            setCurrentSale(undefined);
            setModalShow(true);
          }}
        >
          Adicionar Venda
        </Button>
      </div>
      <div className="table-responsive mt-4">
        <Table striped hover responsive>
          <thead className="thead-dark table table-dark">
            <tr>
              <th>Ações</th>
              <th>ID da Venda</th>
              <th>Data</th>
              <th>Comprador</th>
              <th>Título do Item</th>
              <th>SKU</th>
              <th>Quantidade</th>
              <th>Preço Unitário</th>
              <th>Preço Total do Item</th>
              <th>Valor Total Pago</th>
              <th>Tarifa de Venda</th>
              <th>Tarifa de Envio</th>
              <th>Tipo de Anúncio</th>
              <th>Status de Entrega</th>
              <th>Total</th>
              <th>Nome do Recebedor</th>
              <th>Endereço</th>
              <th>Cidade</th>
              <th>Estado</th>
              <th>CEP</th>
              <th>Telefone</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={21} className="text-center">
                  Carregando...
                </td>
              </tr>
            ) : sales.length > 0 ? (
              sales.map((sale) => (
                <tr key={sale.id}>
                  <td>
                    <Button variant="warning" onClick={() => handleEdit(sale)}>
                      Editar
                    </Button>{' '}
                    <Button
                      variant="danger"
                      onClick={() => handleDelete(sale.id)}
                    >
                      Excluir
                    </Button>
                  </td>
                  <td>{sale.id_venda}</td>
                  <td>{new Date(sale.date_created).toLocaleString()}</td>
                  <td>{sale.buyer_nickname}</td>
                  <td>{sale.item_title}</td>
                  <td>{sale.seller_sku}</td>
                  <td>{sale.quantity}</td>
                  <td>{formatCurrency(sale.unit_price)}</td>
                  <td>{formatCurrency(sale.full_unit_price)}</td>
                  <td>{formatCurrency(sale.total_paid_amount)}</td>
                  <td>{formatCurrency(sale.sale_fee)}</td>
                  <td>{formatCurrency(sale.shipping_cost)}</td>
                  <td>{sale.listing_type}</td>
                  <td>{sale.delivery_status}</td>
                  <td>{formatCurrency(sale.total)}</td>
                  <td>{sale.receiver_name}</td>
                  <td>{sale.address_line}</td>
                  <td>{sale.city}</td>
                  <td>{sale.state}</td>
                  <td>{sale.zip_code}</td>
                  <td>{sale.receiver_phone}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={21} className="text-center">
                  Nenhuma venda encontrada.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <CustomPagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
      <SalesModal
        show={modalShow}
        handleClose={() => setModalShow(false)}
        sale={currentSale}
        handleSave={handleSave}
      />
    </>
  );
};

export default SalesTable;
