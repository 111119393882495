import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Conta, ApiResponse } from '../../components/types';
import {
  Modal,
  Button,
  Form,
  Table,
  Spinner,
  Alert,
  InputGroup,
  FormControl,
} from 'react-bootstrap';

const Contas: React.FC = () => {
  const [contas, setContas] = useState<Conta[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(5);
  const [total, setTotal] = useState<number>(0);
  const [to, setTo] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedConta, setSelectedConta] = useState<Conta | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [newConta, setNewConta] = useState({
    nome: '',
    email: '',
    ativo: true,
    imposto: 0,
  });
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ nome?: string; email?: string }>({});

  const fetchContas = async (page: number, searchQuery: string = '') => {
    setLoading(true);
    setError(null);

    const token = sessionStorage.getItem('authToken');
    if (!token) {
      setError('Token não encontrado. Por favor, faça login novamente.');
      setLoading(false);
      return;
    }

    try {
      let url =
        import.meta.env.VITE_APP_API_URL +
        `/meli-contas?page=${page}&per_page=${perPage}`;
      if (searchQuery) {
        url += `&search=${searchQuery}`;
      }

      const response = await axios.get<ApiResponse>(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setContas(response.data.data);
      setCurrentPage(response.data.meta.current_page);
      setTotalPages(response.data.meta.last_page);
      setPerPage(response.data.meta.per_page);
      setTotal(response.data.meta.total);
      setTo(response.data.meta.to);
    } catch (error) {
      console.error('Erro ao buscar contas:', error);
      setError('Erro ao buscar contas.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContas(currentPage);
  }, [currentPage]);

  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSearchKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      fetchContas(1, search);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedConta(null);
  };

  const handleShowCreateModal = () => {
    setShowCreateModal(true);
  };

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
    setNewConta({ nome: '', email: '', ativo: true, imposto: 0 });
    setErrors({});
    setIsEditing(false);
  };

  const handleShowDeleteModal = (conta: Conta) => {
    setSelectedConta(conta);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedConta(null);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewConta({
      ...newConta,
      [name]: name === 'imposto' ? parseFloat(value) || 0 : value,
    });
  };

  const validateForm = () => {
    const { nome, email } = newConta;
    const newErrors: { nome?: string; email?: string } = {};
    if (!nome) {
      newErrors.nome = 'Nome é obrigatório';
    }
    if (!email) {
      newErrors.email = 'Email é obrigatório';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Email inválido';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCreateConta = async () => {
    if (!validateForm()) {
      return;
    }

    setIsSaving(true);
    const token = sessionStorage.getItem('authToken');
    if (!token) {
      setError('Token não encontrado. Por favor, faça login novamente.');
      setIsSaving(false);
      return;
    }

    try {
      await axios.post(
        import.meta.env.VITE_APP_API_URL + '/meli-contas',
        newConta,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      handleCloseCreateModal();
      fetchContas(currentPage); // Atualiza a lista de contas
    } catch (error) {
      console.error('Erro ao criar conta:', error);
      setError('Erro ao criar conta.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleEditConta = (conta: Conta) => {
    setSelectedConta(conta);
    setNewConta({
      nome: conta.nome,
      email: conta.email,
      ativo: conta.ativo,
      imposto: conta.imposto,
    });
    setIsEditing(true);
    setShowCreateModal(true);
  };

  const handleUpdateConta = async () => {
    if (!validateForm()) {
      return;
    }

    setIsSaving(true);
    const token = sessionStorage.getItem('authToken');
    if (!token) {
      setError('Token não encontrado. Por favor, faça login novamente.');
      setIsSaving(false);
      return;
    }

    try {
      await axios.put(
        import.meta.env.VITE_APP_API_URL + `/meli-contas/${selectedConta?.id}`,
        newConta,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      handleCloseCreateModal();
      fetchContas(currentPage); // Atualiza a lista de contas
    } catch (error) {
      console.error('Erro ao atualizar conta:', error);
      setError('Erro ao atualizar conta.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeleteConta = async () => {
    setIsSaving(true);
    const token = sessionStorage.getItem('authToken');
    if (!token) {
      setError('Token não encontrado. Por favor, faça login novamente.');
      setIsSaving(false);
      return;
    }

    try {
      await axios.delete(
        import.meta.env.VITE_APP_API_URL + `/meli-contas/${selectedConta?.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      handleCloseDeleteModal();
      fetchContas(currentPage); // Atualiza a lista de contas
    } catch (error) {
      console.error('Erro ao excluir conta:', error);
      setError('Erro ao excluir conta.');
    } finally {
      setIsSaving(false);
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
    let startPage = Math.max(1, currentPage - halfMaxPagesToShow);
    let endPage = Math.min(totalPages, currentPage + halfMaxPagesToShow);

    if (currentPage <= halfMaxPagesToShow) {
      endPage = Math.min(totalPages, maxPagesToShow);
    } else if (currentPage + halfMaxPagesToShow >= totalPages) {
      startPage = Math.max(1, totalPages - maxPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  return (
    <div className="container-fluid">
      <h1 className="text-center mb-4">Contas</h1>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">@</span>
          </Spinner>
        </div>
      ) : error ? (
        <Alert variant="danger" className="text-center">
          {error}
        </Alert>
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <Button variant="success" onClick={handleShowCreateModal}>
              Cadastrar Conta
            </Button>
          </div>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Pesquisar por nome ou email"
              aria-label="Pesquisar por nome ou email"
              value={search}
              onChange={handleSearchChange}
              onKeyDown={handleSearchKeyPress}
            />
          </InputGroup>
          <div className="table-responsive">
            <Table hover className="table-striped">
              <thead className="thead-dark table table-dark">
                <tr>
                  <th>ID</th>
                  <th>Nome</th>
                  <th>Email</th>
                  <th>Ativo</th>
                  <th>Imposto</th>
                  <th>Data de Criação</th>
                  <th>Última Atualização</th>
                  <th>Ações</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {contas.map((conta) => (
                  <tr key={conta.id}>
                    <td>{conta.id}</td>
                    <td>{conta.nome}</td>
                    <td>{conta.email}</td>
                    <td>{conta.ativo ? 'Sim' : 'Não'}</td>
                    <td>{conta.imposto}</td>
                    <td>{new Date(conta.created_at).toLocaleDateString()}</td>
                    <td>{new Date(conta.updated_at).toLocaleDateString()}</td>
                    <td>
                      <Button
                        variant="primary"
                        size="sm"
                        className="mr-2"
                        onClick={() => handleEditConta(conta)}
                      >
                        Editar
                      </Button>
                    </td>
                    <td>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleShowDeleteModal(conta)}
                      >
                        Excluir
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <br />
          <p className="text-muted">
            Mostrando {to} de {total} contas
          </p>
          <nav>
            <ul className="pagination justify-content-center">
              <li
                className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
              >
                <Button
                  className="page-link"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Anterior
                </Button>
              </li>
              {getPageNumbers().map((pageNumber) => (
                <li
                  key={pageNumber}
                  className={`page-item ${
                    currentPage === pageNumber ? 'active' : ''
                  }`}
                >
                  <Button
                    className="page-link"
                    onClick={() => handlePageChange(pageNumber)}
                  >
                    {pageNumber}
                  </Button>
                </li>
              ))}
              <li
                className={`page-item ${
                  currentPage === totalPages ? 'disabled' : ''
                }`}
              >
                <Button
                  className="page-link"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Próxima
                </Button>
              </li>
            </ul>
          </nav>
        </>
      )}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Detalhes da Conta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedConta && (
            <div>
              <p>
                <strong>ID:</strong> {selectedConta.id}
              </p>
              <p>
                <strong>Nome:</strong> {selectedConta.nome}
              </p>
              <p>
                <strong>Email:</strong> {selectedConta.email}
              </p>
              <p>
                <strong>Ativo:</strong> {selectedConta.ativo ? 'Sim' : 'Não'}
              </p>
              <p>
                <strong>Imposto:</strong> {selectedConta.imposto}
              </p>
              <p>
                <strong>Data de Criação:</strong>{' '}
                {new Date(selectedConta.created_at).toLocaleDateString()}
              </p>
              <p>
                <strong>Última Atualização:</strong>{' '}
                {new Date(selectedConta.updated_at).toLocaleDateString()}
              </p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showCreateModal} onHide={handleCloseCreateModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? 'Editar Conta' : 'Cadastrar Nova Conta'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="nome">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type="text"
                name="nome"
                placeholder="Nome da conta"
                value={newConta.nome}
                onChange={handleInputChange}
                isInvalid={!!errors.nome}
              />
              <Form.Control.Feedback type="invalid">
                {errors.nome}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Email da conta"
                value={newConta.email}
                onChange={handleInputChange}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="imposto">
              <br />
              <span style={{ color: 'red' }}>
                O valor do imposto é calculado com base na porcentagem de
                imposto e no valor da venda
              </span>
              <br />
              <Form.Label>Imposto</Form.Label>
              <Form.Control
                type="number"
                name="imposto"
                placeholder="Imposto da conta"
                step="0.01"
                value={newConta.imposto}
                onChange={handleInputChange}
              />
            </Form.Group>
            <br />
            <Form.Group controlId="ativo">
              <Form.Label>Ativo</Form.Label>
              <Form.Check
                type="checkbox"
                name="ativo"
                checked={newConta.ativo}
                onChange={() =>
                  setNewConta({ ...newConta, ativo: !newConta.ativo })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCreateModal}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={isEditing ? handleUpdateConta : handleCreateConta}
            disabled={isSaving}
          >
            {isSaving ? (
              <Spinner
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : isEditing ? (
              'Atualizar'
            ) : (
              'Cadastrar'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tem certeza de que deseja excluir a conta {selectedConta?.nome}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancelar
          </Button>
          <Button
            variant="danger"
            onClick={handleDeleteConta}
            disabled={isSaving}
          >
            {isSaving ? (
              <Spinner
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              'Excluir'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Contas;
