import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form, Button, Spinner, Modal, Alert } from 'react-bootstrap';
import './EditMeliProduct.css';

interface EditMeliProductProps {
  id?: string; // Tornar a prop opcional
  onClose: () => void; // Nova prop para fechar o modal
  onSaveSuccess: () => void; // Nova prop para chamar handleIntegrationCheck
}

const EditMeliProduct: React.FC<EditMeliProductProps> = ({
  id,
  onClose,
  onSaveSuccess,
}) => {
  const { id: routeId } = useParams<{ id: string }>();
  const [product, setProduct] = useState<any>(null);
  const [description, setDescription] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isUnderReview, setIsUnderReview] = useState<boolean>(false);
  const [hasBids, setHasBids] = useState<boolean>(false); // Novo estado para has_bids

  const productId = id || routeId; // Use o id da prop ou da rota

  const token = sessionStorage.getItem('ml_access_token');
  if (!token) {
    console.error('Token de acesso não encontrado.');
    toast.error('Erro ao salvar o produto: Token de acesso não encontrado.');
    return;
  }

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setLoading(true);
        const token = sessionStorage.getItem('ml_access_token');

        // Fetch the main product data
        const productResponse = await axios.get(
          `https://api.mercadolibre.com/items/${productId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              attributes:
                'status,has_bids,title,price,available_quantity,description', // Incluindo has_bids na requisição
            },
          }
        );

        const productData = productResponse.data;
        setProduct(productData);

        // Verifica se o status é "under_review"
        if (productData.status === 'under_review') {
          setIsUnderReview(true);
        }

        // Verifica se o produto possui lances
        if (productData.has_bids) {
          setHasBids(true);
        }

        // Fetch the product description
        const descriptionResponse = await axios.get(
          `https://api.mercadolibre.com/items/${productId}/description`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setDescription(descriptionResponse.data.plain_text || '');
      } catch (error) {
        toast.error('Erro ao carregar o produto ou descrição.');
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [productId]);

  const handleSave = async () => {
    let updatedProduct: any; // Declaração fora do try
    let token: string | null; // Declaração fora do try

    try {
      setSaving(true);
      token = sessionStorage.getItem('ml_access_token'); // Agora está no escopo correto
      updatedProduct = { ...product }; // Agora está no escopo correto

      // Verifica se has_bids está ativado e remove campos não atualizáveis
      if (hasBids) {
        console.log(
          'Produto possui lances ativos. Removendo título da atualização.'
        );
        delete updatedProduct.title;
      }

      console.log('Dados sendo enviados inicialmente:', updatedProduct);

      await axios.put(
        `https://api.mercadolibre.com/items/${productId}`,
        updatedProduct,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('Produto atualizado com sucesso:', updatedProduct);

      await axios.put(
        `https://api.mercadolibre.com/items/${productId}/description`,
        { plain_text: description },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('Descrição atualizada com sucesso.');
      toast.success('Anúncio atualizado com sucesso!');
      onClose();
      onSaveSuccess();
    } catch (error: any) {
      console.log('Erro ao tentar salvar o produto:', error);

      if (
        error.response &&
        error.response.data &&
        error.response.data.error === 'validation_error'
      ) {
        console.log('Erro de validação recebido da API:', error.response.data);

        const causes = error.response.data.cause;
        const nonUpdatableFields = causes
          .map((cause: any) => cause.references)
          .flat();

        nonUpdatableFields.forEach((field: string) => {
          if (field.startsWith('item.')) {
            const fieldName = field.replace('item.', '');
            console.log(
              `Campo não atualizável detectado: ${fieldName}. Removendo...`
            );
            delete updatedProduct[fieldName]; // Remove o campo não atualizável
            toast.warn(
              `O campo ${fieldName} não pode ser atualizado porque o anúncio possui lances.`
            );
          }
        });

        console.log(
          'Tentando salvar novamente com os campos removidos:',
          updatedProduct
        );

        // Tente salvar novamente, sem os campos não atualizáveis
        try {
          const token = sessionStorage.getItem('ml_access_token');

          await axios.put(
            `https://api.mercadolibre.com/items/${productId}`,
            updatedProduct,
            {
              headers: {
                Authorization: `Bearer ${token}`, // Token agora está no escopo correto
                'Content-Type': 'application/json',
              },
            }
          );
          console.log(
            'Produto atualizado com sucesso após remoção de campos não atualizáveis:',
            updatedProduct
          );
          toast.success('Anúncio atualizado com sucesso!');
          onClose();
          onSaveSuccess();
        } catch (retryError) {
          console.error(
            'Erro ao salvar após remover campos não atualizáveis:',
            retryError
          );
          toast.error(
            'Erro ao salvar o produto após remoção de campos não atualizáveis.'
          );
        }
      } else {
        console.error('Erro desconhecido ao salvar o produto:', error);
        toast.error('Erro ao salvar o produto.');
      }
    } finally {
      setSaving(false);
      setShowModal(false);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;

    if (name === 'price') {
      // Remove a formatação antes de salvar no estado
      const numericValue = parseFloat(value.replace(',', '.'));
      setProduct((prevProduct: any) => ({
        ...prevProduct,
        [name]: isNaN(numericValue) ? '' : numericValue,
      }));
    } else if (name === 'plain_text') {
      setDescription(value);
    } else {
      setProduct((prevProduct: any) => ({
        ...prevProduct,
        [name]: value,
      }));
    }

    if (name === 'plain_text') {
      setDescription(value);
    } else {
      setProduct((prevProduct: any) => ({
        ...prevProduct,
        [name]: value,
      }));
    }
  };

  const handleConfirmSave = () => {
    setShowModal(true);
  };

  if (loading) {
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">@</span>
      </Spinner>
    );
  }

  return (
    <div className="edit-product-container">
      <Form className="edit-product-form">
        <Form.Group>
          <Form.Label>Título</Form.Label>
          <Form.Control
            type="text"
            name="title"
            value={product.title || ''}
            onChange={handleChange}
            disabled={isUnderReview || hasBids}
            className="form-input"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Preço</Form.Label>
          <Form.Control
            type="number"
            name="price"
            value={product.price || ''}
            onChange={handleChange}
            disabled={isUnderReview || hasBids}
            className="form-input"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Quantidade Disponível</Form.Label>
          <Form.Control
            type="number"
            name="available_quantity"
            value={product.available_quantity || ''}
            onChange={handleChange}
            disabled={isUnderReview || hasBids}
            className="form-input"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Status</Form.Label>
          <Form.Control
            as="select"
            name="status"
            value={product.status || ''}
            onChange={handleChange}
            disabled={isUnderReview || hasBids}
            className="form-select"
          >
            <option value="active">Ativo</option>
            <option value="paused">Pausado</option>
            <option value="closed">Fechado</option>
            <option value="under_review">Em revisão</option>
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Descrição</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            name="plain_text"
            value={description}
            onChange={handleChange}
            className="no-border-radius"
            style={{
              height: '230px',
              overflowY: 'auto',
              borderRadius: '0',
              border: '1px solid #ced4da',
            }}
            disabled={isUnderReview || hasBids}
          />
        </Form.Group>
        <br />
        <div className="form-actions">
          <Button
            variant="primary"
            onClick={handleConfirmSave}
            disabled={saving || isUnderReview || hasBids}
            className="btn-save"
          >
            {saving ? 'Salvando...' : 'Salvar'}
          </Button>
        </div>
      </Form>

      {/* Modal de Confirmação */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmação de Alterações</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Tem certeza de que deseja salvar as seguintes alterações?</p>
          <ul>
            <li>
              <strong>Título:</strong> {product.title}
            </li>
            <li>
              <strong>Preço:</strong> {product.price}
            </li>
            <li>
              <strong>Quantidade Disponível:</strong>{' '}
              {product.available_quantity}
            </li>
            <li>
              <strong>Status:</strong> {product.status}
            </li>
            <li>
              <strong>Descrição:</strong> {description}
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModal(false)}
            className="btn-cancel"
          >
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={saving}
            className="btn-confirm"
          >
            {saving ? 'Salvando...' : 'Confirmar e Salvar'}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Alerta para has_bids */}
      {hasBids && (
        <Alert variant="warning" className="mt-3">
          Este anúncio possui lances ativos e, portanto, não pode ser editado.
        </Alert>
      )}

      {/* Alerta para under_review */}
      {isUnderReview && (
        <Alert variant="danger" className="mt-3">
          Este anúncio está atualmente "Em Revisão" pelo Mercado Livre. Durante
          esse período, nenhuma alteração pode ser realizada.
        </Alert>
      )}
    </div>
  );
};

export default EditMeliProduct;
