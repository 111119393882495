import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Spinner, Alert } from 'react-bootstrap';
import Chart from 'chart.js/auto';

const BiEstoqueMin: React.FC = () => {
  const [data, setData] = useState<
    {
      descricao: string;
      sku: string;
      quantidade_minima: number;
      nome: string;
    }[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstanceRef = useRef<Chart | null>(null);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!loading && !error && chartRef.current) {
      const ctx = chartRef.current.getContext('2d');

      // Destruir o gráfico existente se houver um
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      if (ctx) {
        chartInstanceRef.current = new Chart(ctx, {
          type: 'bar', // Tipo de gráfico permanece 'bar'
          data: {
            labels: data.map((item) => item.descricao),
            datasets: [
              {
                label: 'Quantidade Mínima',
                data: data.map((item) => item.quantidade_minima),
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
              },
            ],
          },
          options: {
            indexAxis: 'y', // Troca o gráfico para barras horizontais
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                beginAtZero: true,
              },
            },
            plugins: {
              title: {
                display: true,
                text: '10 Produtos com Menor Estoque',
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    return `Quantidade Mínima: ${context.raw}`;
                  },
                },
              },
            },
          },
        });
      }
    }

    // Destruir o gráfico quando o componente for desmontado
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
        chartInstanceRef.current = null;
      }
    };
  }, [loading, error, data]);

  const fetchData = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/bi_estoqueMin`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('authToken')}`,
          },
        }
      );
      setData(response.data.data);
    } catch (error) {
      console.error('Erro ao buscar dados de estoque mínimo:', error);
      setError('Erro ao buscar dados de estoque mínimo.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only"></span>
          </Spinner>
        </div>
      ) : error ? (
        <Alert variant="danger" className="text-center">
          {error}
        </Alert>
      ) : (
        <div style={{ height: '400px' }}>
          <canvas ref={chartRef}></canvas>
        </div>
      )}
    </div>
  );
};

export default BiEstoqueMin;
