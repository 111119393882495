import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  Button,
  Form,
  Table,
  Spinner,
  ProgressBar,
  Tab,
  Tabs,
  Modal,
} from 'react-bootstrap';
import { refreshToken } from '../../../components/Token/refreshToken';
import {
  FaTrash,
  FaCloudUploadAlt,
  FaArrowLeft,
  FaArrowRight,
} from 'react-icons/fa';
import { formatCurrency } from '../../../utils/formatCurrency';
import ProductDetails from '../../Produtos/components/ProductDetails';
import ExcelExportButton from './ExcelExportButton'; // Importe o novo componente

import './MeliProductImporter.css';
import { translateCondition, translateStatus } from '../../../utils/';
import { Anuncio, MeliAccount } from '../../../components/types'; // Importe a interface Anuncio
import EditMeliProduct from './EditMeliProduct';

const MeliProductImporter: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [importing, setImporting] = useState<boolean>(false);
  const [importProgress, setImportProgress] = useState<number>(0);
  const [products, setProducts] = useState<Anuncio[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Anuncio[]>([]);
  const [meliAccounts, setMeliAccounts] = useState<MeliAccount[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<string | null>(null);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [limit, setLimit] = useState<number>(50);
  const [offset, setOffset] = useState<number>(0);
  const [totalProducts, setTotalProducts] = useState<number>(0);
  const [failedImports, setFailedImports] = useState<Anuncio[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [status, setStatus] = useState<string>('active');
  const authToken = sessionStorage.getItem('authToken');
  const [exporting, setExporting] = useState<boolean>(false);
  const [duplicatedSkus, setDuplicatedSkus] = useState<string[]>([]);
  const [selectedProductId, setSelectedProductId] = useState<string | null>(
    null
  );
  const [showModal, setShowModal] = useState<boolean>(false); // Estado para controlar a modal
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [editProductId, setEditProductId] = useState<string | null>(null);

  const handleEditProduct = (productId: string) => {
    setEditProductId(productId);
    setShowEditModal(true);
  };

  if (!authToken) {
    console.error('Token não encontrado. Por favor, faça login novamente.');
    return null;
  }

  const fetchMeliAccounts = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/meli-contas`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setMeliAccounts(response.data.data);
    } catch (error) {
      console.error('Erro ao buscar contas do Mercado Livre:', error);
      toast.error('Erro ao buscar contas do Mercado Livre');
    }
  };

  const fetchProducts = async () => {
    const token = sessionStorage.getItem('ml_access_token');
    const userId = sessionStorage.getItem('ml_user_id');

    if (!token || !userId) {
      handleIntegrationCheck();
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/meli/products`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            seller_id: userId,
            status: status || undefined,
            limit,
            offset,
          },
        }
      );
      const productIds = response.data.results;

      const productDetails = await Promise.all(
        productIds.map(async (id: string) => {
          const res = await axios.get(
            `https://api.mercadolibre.com/items/${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const product = res.data;
          // Verifique se a categoria está presente e obtenha seu nome
          let categoryName = '';
          if (product.category_id) {
            const categoryResponse = await axios.get(
              `https://api.mercadolibre.com/categories/${product.category_id}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            categoryName = categoryResponse.data.name;
          }

          return {
            id: product.id,
            title: product.title,
            seller_sku:
              product.attributes.find((attr: any) => attr.id === 'SELLER_SKU')
                ?.value_name || '',
            initial_quantity: product.initial_quantity,
            available_quantity: product.available_quantity,
            sold_quantity: product.sold_quantity,
            price: product.price,
            original_price: product.original_price,
            currency_id: product.currency_id,
            condition: translateCondition(product.condition),
            permalink: product.permalink,
            thumbnail: product.thumbnail,
            status: product.status,
            category_id: product.category_id || '', // Captura corretamente o category_id do Mercado Livre
            category_name: categoryName, // Adicionando o nome da categoria
          };
        })
      );

      console.log('Product Details:', productDetails); // Log para depuração

      setProducts(productDetails);
      setFilteredProducts(productDetails);
      setTotalProducts(response.data.paging.total);
      toast.success('Produtos buscados com sucesso!');
    } catch (error) {
      console.error('Erro ao buscar produtos:', error);
      toast.error('Erro ao buscar produtos.');
    } finally {
      setLoading(false);
    }
  };

  //handleShowDetails e handleCloseModal

  const handleShowDetails = (productId: string) => {
    setSelectedProductId(productId);
    setShowModal(true); // Abre a modal quando o produto é selecionado
  };

  const handleCloseModal = () => {
    setShowModal(false); // Fecha a modal
  };

  // Adicione a função handleNextPage para avançar para a próxima página
  const handleNextPage = () => {
    if (offset + limit < totalProducts) {
      setOffset(offset + limit);
    }
  };

  // Adicione a função handlePreviousPage para voltar para a página anterior
  const handlePreviousPage = () => {
    if (offset > 0) {
      setOffset(offset - limit);
    }
  };

  // Adicione a função handleIntegrationCheck para verificar a integração
  const handleIntegrationCheck = async () => {
    if (selectedAccount) {
      setLoading(true);
      try {
        await refreshToken(selectedAccount, setLoading);
        await fetchProducts();
      } catch (error) {
        console.error('Erro ao verificar integração:', error);
        toast.error('Erro ao verificar integração.');
      } finally {
        setLoading(false);
      }
    } else {
      toast.error('Selecione uma conta do Mercado Livre.');
    }
  };

  // Adicione o useEffect para buscar os produtos ao carregar a página
  useEffect(() => {
    fetchMeliAccounts();
  }, [authToken]);

  useEffect(() => {
    if (selectedAccount) {
      fetchProducts();
    }
  }, [offset]);

  useEffect(() => {
    const filtered = products.filter(
      (product) =>
        product.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product.seller_sku.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredProducts(filtered);
  }, [searchQuery, products]);

  // Adicione o useEffect para buscar os produtos ao carregar a página
  const handleSelectProduct = (
    e: React.ChangeEvent<HTMLInputElement>,
    productId: string
  ) => {
    if (e.target.checked) {
      setSelectedProducts((prevSelectedProducts) => [
        ...prevSelectedProducts,
        productId,
      ]);
    } else {
      setSelectedProducts((prevSelectedProducts) =>
        prevSelectedProducts.filter((id) => id !== productId)
      );
    }
  };

  // Adicione a função handleSelectAll para selecionar todos os produtos
  const handleSelectAll = () => {
    if (selectedProducts.length === filteredProducts.length) {
      setSelectedProducts([]);
    } else {
      const enabledProducts = filteredProducts
        .filter((product) => product.seller_sku)
        .map((product) => product.id);
      setSelectedProducts(enabledProducts);
    }
  };

  // Adicione a função checkProductBySku para verificar se o produto já existe
  const checkProductBySku = async (sku: string): Promise<boolean> => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/produtos/find-by-sku/${sku}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      return response.data.isok === true; // Retorna `true` se isok for `true`
    } catch (error) {
      console.error('Erro ao verificar o SKU:', error);
      return false; // Retorna `false` em caso de erro
    }
  };

  // Adicione a função fetchOrCreateCategoria para buscar ou criar uma categoria
  const fetchOrCreateCategoria = async (
    categoryId: string,
    categoryName: string
  ): Promise<number | null> => {
    const token = sessionStorage.getItem('authToken');

    try {
      // Tenta buscar a categoria pelo ID do Mercado Livre
      const response = await axios.get(
        `${
          import.meta.env.VITE_APP_API_URL
        }/findbycategoria?ml_category_id=${categoryId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Se a categoria for encontrada, retorna o ID
      if (response.data && response.data.id) {
        return response.data.id;
      }

      // Caso contrário, retorna null (não deveria alcançar este ponto se a API estiver correta)
      return null;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        // Categoria não encontrada (404)
        console.warn(
          `Categoria com ID "${categoryId}" não encontrada. Criando nova categoria...`
        );

        try {
          // Criar uma nova categoria quando não encontrada
          const newCategoryResponse = await axios.post(
            `${import.meta.env.VITE_APP_API_URL}/categorias`,
            {
              nome: categoryName,
              categoria_id: categoryId, // Associa o ID da categoria do Mercado Livre
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );
          return newCategoryResponse.data.data.id;
        } catch (createError) {
          console.error(
            `Erro ao criar a nova categoria "${categoryName}" com ID "${categoryId}":`,
            createError
          );
          toast.error(
            `Erro ao criar a nova categoria "${categoryName}" com ID "${categoryId}".`
          );
          return null;
        }
      } else {
        // Outros erros (pode ser um erro de conexão, por exemplo)
        console.error(
          `Erro ao buscar ou criar a categoria "${categoryName}" com ID "${categoryId}":`,
          error
        );
        toast.error(
          `Erro ao buscar ou criar a categoria "${categoryName}" com ID "${categoryId}".`
        );
        return null;
      }
    }
  };

  // Adicione a função handleImportProducts para importar os produtos selecionados
  const handleImportProducts = async () => {
    const token = sessionStorage.getItem('authToken');
    if (!token) {
      toast.error('Token ausente');
      return;
    }

    setImporting(true);
    setImportProgress(0);
    setFailedImports([]);

    try {
      for (let i = 0; i < selectedProducts.length; i++) {
        const productId = selectedProducts[i];
        const product = filteredProducts.find(
          (product) => product.id === productId
        );

        if (product) {
          try {
            // Busca ou cria a categoria e obtém o ID para cada produto individualmente
            const categoriaId = await fetchOrCreateCategoria(
              product.category_id || '', // ID da categoria do Mercado Livre
              product.category_name || '' // Nome da categoria do Mercado Livre
            );

            // Dados do produto a serem enviados para o backend
            const productData = {
              descricao: product.title,
              sku: product.seller_sku,
              user_email: sessionStorage.getItem('userEmail') || '',
              meli_product_id: product.id,
              price: product.price,
              original_price: product.original_price,
              currency_id: product.currency_id,
              available_quantity: product.available_quantity,
              condition: product.condition,
              permalink: product.permalink,
              thumbnail: product.thumbnail,
              categoria_id: categoriaId ?? null,
              meli_conta_id: selectedAccount, // Incluindo a meli_conta_id              // Associa a categoria ao produto
            };

            const skuExists = await checkProductBySku(product.seller_sku);

            if (skuExists) {
              setDuplicatedSkus((prev) => [...prev, product.seller_sku]);
              continue; // Pula a importação deste produto
            }

            await axios.post(
              `${import.meta.env.VITE_APP_API_URL}/produtos`,
              productData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              }
            );
          } catch (error) {
            setFailedImports((prev) => [...prev, product]);
            console.error(`Erro ao importar produto ID ${product.id}:`, error);
          }

          setImportProgress(
            Math.round(((i + 1) / selectedProducts.length) * 100)
          );
        }
      }

      if (failedImports.length > 0) {
        toast.error(`Erro ao importar alguns produtos.`);
      }

      toast.success('Processamento feito com sucesso!');
    } catch (error) {
      console.error('Erro ao importar produtos:', error);
      toast.error('Erro ao importar produtos.');
    } finally {
      setImporting(false);
    }
  };

  // Adicione a função handleImportProductsWithStock para importar os produtos com estoque
  const handleImportProductsWithStock = async () => {
    const token = sessionStorage.getItem('authToken');
    if (!token) {
      toast.error('Token ausente');
      return;
    }

    setImporting(true);
    setImportProgress(0);
    setFailedImports([]);

    try {
      for (let i = 0; i < selectedProducts.length; i++) {
        const productId = selectedProducts[i];
        const product = filteredProducts.find(
          (product) => product.id === productId
        );

        if (product) {
          try {
            // Busca ou cria a categoria e obtém o ID para cada produto individualmente
            const categoriaId = await fetchOrCreateCategoria(
              product.category_id || '', // ID da categoria do Mercado Livre
              product.category_name || '' // Nome da categoria do Mercado Livre
            );

            let produtoId = null;

            const skuExists = await checkProductBySku(product.seller_sku);

            if (skuExists) {
              setDuplicatedSkus((prev) => [...prev, product.seller_sku]);
              continue; // Pula a importação deste produto
            }

            const productData = {
              descricao: product.title,
              sku: product.seller_sku,
              user_email: sessionStorage.getItem('userEmail') || '',
              meli_product_id: product.id,
              price: product.price,
              original_price: product.original_price,
              currency_id: product.currency_id,
              available_quantity: product.available_quantity,
              condition: product.condition,
              permalink: product.permalink,
              thumbnail: product.thumbnail,
              categoria_id: categoriaId ?? null,
              meli_conta_id: selectedAccount, // Incluindo a meli_conta_id
              // Associa a categoria ao produto
            };
            const newProduct = await axios.post(
              `${import.meta.env.VITE_APP_API_URL}/produtos`,
              productData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              }
            );
            produtoId = newProduct.data.data.id;

            await handleSalvarAjusteEstoqueLocal(produtoId, product, token);
          } catch (error) {
            console.error(`Erro ao importar produto ID ${product.id}:`, error);
            setFailedImports((prev) => [...prev, product]);
          }

          setImportProgress(
            Math.round(((i + 1) / selectedProducts.length) * 100)
          );
        }
      }

      if (failedImports.length > 0) {
        toast.error(`Erro ao importar alguns produtos.`);
      }

      toast.success('Importação de Produtos com Estoque feita com sucesso!');
    } catch (error: any) {
      console.error('Erro ao importar produtos e estoques:', error.message);
      toast.error('Erro ao importar produtos e estoques.');
    } finally {
      setImporting(false);
    }
  };

  // Adicione a função handleSalvarAjusteEstoqueLocal para salvar o ajuste de estoque
  const handleSalvarAjusteEstoqueLocal = async (
    produtoId: string,
    product: Anuncio,
    token: string
  ) => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/estoques/check/${produtoId}/0`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const estoqueExistente = response.data.exists;

      if (estoqueExistente) {
        const estoque = response.data.estoque;
        const novaQuantidade = product.available_quantity;

        await axios.put(
          `${import.meta.env.VITE_APP_API_URL}/estoques/${estoque.id}`,
          {
            produto_id: produtoId,
            quantidade: novaQuantidade,
            meli_conta_id: 0,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        await axios.post(
          `${import.meta.env.VITE_APP_API_URL}/movimento-estoques`,
          {
            estoque_id: estoque.id,
            produto_id: produtoId,
            quantidade: novaQuantidade,
            tipo: 'Importação automática Produto Meli Estoque Ajustado',
            usuario: sessionStorage.getItem('userEmail') || '',
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        try {
          // Primeira requisição para criar o estoque
          const estoqueResponse = await axios.post(
            `${import.meta.env.VITE_APP_API_URL}/estoques`,
            {
              produto_id: produtoId,
              quantidade: product.available_quantity,
              meli_conta_id: 0, // Atribua o ID da conta Mercado Livre correto se necessário
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          // Captura o estoque_id do retorno da primeira requisição
          const estoqueId = estoqueResponse.data.data.id;

          // Segunda requisição para criar o movimento de estoque, usando o estoqueId
          await axios.post(
            `${import.meta.env.VITE_APP_API_URL}/movimento-estoques`,
            {
              estoque_id: estoqueId, // Usando o estoque_id capturado
              produto_id: produtoId,
              quantidade: product.available_quantity,
              tipo: 'Importação automática Produto Meli Estoque Criado',
              usuario: sessionStorage.getItem('userEmail') || '',
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } catch (error) {
          console.error(
            'Erro ao criar estoque ou movimento de estoque:',
            error
          );
        }
      }
    } catch (error) {
      toast.error('Erro ao ajustar o estoque.');
      console.error('Erro ao ajustar o estoque:', error);
    }
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  return (
    <div className="meli-importer-container">
      {/* Seção do Header */}
      <div className="header-section">
        <div>
          <h3>Anúncios Mercado Livre</h3>
        </div>
        <div className="d-flex align-items-center">
          <h5>Total de Anúncios: {totalProducts}</h5>
        </div>
      </div>

      {/* Seção de Filtros e Ações */}
      <div className="card">
        <div className="row">
          <div className="col-md-4">
            <Form.Group>
              <Form.Label>Conta do Mercado Livre</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => setSelectedAccount(e.target.value)}
                disabled={loading || importing}
              >
                <option value="">Selecione uma conta</option>
                {meliAccounts.map((account) => (
                  <option key={account.id} value={account.id}>
                    {account.nome} {account.ativo ? '(Ativo)' : ''}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>

          <div className="col-md-3">
            <Form.Group>
              <Form.Label>Status do Produto</Form.Label>
              <Form.Control
                as="select"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                disabled={loading || importing}
              >
                <option value="">Todos</option>
                <option value="active">Ativo</option>
                <option value="paused">Pausado</option>
                <option value="closed">Fechado</option>
              </Form.Control>
            </Form.Group>
          </div>

          <div className="col-md-2">
            <Form.Group>
              <Form.Label>Limite por Página</Form.Label>
              <Form.Control
                as="select"
                value={limit}
                onChange={(e) => setLimit(Number(e.target.value))}
                disabled={loading || importing}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </Form.Control>
            </Form.Group>
          </div>

          <div className="col-md-3 d-flex align-items-end">
            <Button
              onClick={handleIntegrationCheck}
              className="w-100"
              variant="primary"
              disabled={loading || importing}
            >
              <img
                src="./mercado_livre.png"
                alt="Mercado Livre"
                style={{ width: '30px', marginRight: '10px' }}
              />
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                'Buscar Produtos'
              )}
            </Button>
          </div>
        </div>
      </div>

      {/* Modal de Detalhes do Produto Selecionado */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg" fullscreen>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {selectedProductId && (
            <ProductDetails productId={selectedProductId} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Seção da Tabela de Produtos */}
      <div className="card">
        <div>
          <Form.Group className="mb-2">
            <Form.Control
              type="text"
              placeholder="Buscar por título ou SKU"
              className="w-100"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Form.Group>
          <div className="col-md-2">
            <ExcelExportButton
              filteredProducts={filteredProducts}
              setExporting={setExporting}
              exporting={exporting}
            />
          </div>
        </div>
        <div className="table-responsive">
          <br />
          <Tabs defaultActiveKey="geral" id="product-tabs" className="mb-3">
            <Tab eventKey="geral" title="Geral">
              <Table responsive="sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>
                      <input
                        type="checkbox"
                        onChange={handleSelectAll}
                        checked={
                          filteredProducts.length > 0 &&
                          selectedProducts.length ===
                            filteredProducts.filter(
                              (product) => product.seller_sku
                            ).length
                        }
                      />
                    </th>
                    <th>ID do Mercado Livre</th>
                    <th>Título</th>
                    <th>SKU</th>
                    <th>Preço</th>
                    <th>Categoria</th>
                    <th>Status</th>
                    <th>Detalhes Completo</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredProducts.length > 0 ? (
                    filteredProducts.map((product, index) => (
                      <tr key={product.id}>
                        <td>{index + 1 + offset}</td>
                        <td>
                          <input
                            type="checkbox"
                            onChange={(e) => handleSelectProduct(e, product.id)}
                            checked={selectedProducts.includes(product.id)}
                            disabled={importing || !product.seller_sku}
                          />
                        </td>
                        <td>{product.id}</td>
                        <td>{product.title}</td>
                        <td>{product.seller_sku}</td>
                        <td>{formatCurrency(product.price ?? 0)}</td>
                        <td>{product.category_name}</td>
                        <td
                          className={
                            product.status === 'active'
                              ? 'status-active'
                              : product.status === 'paused'
                              ? 'status-paused'
                              : product.status === 'under_review'
                              ? 'status-under-review'
                              : ''
                          }
                        >
                          {translateStatus(product.status ?? '')}
                        </td>
                        <td>
                          <Button
                            variant="info"
                            onClick={() => handleShowDetails(product.id)}
                          >
                            Detalhes Completo
                          </Button>
                        </td>
                        <td>
                          <Button
                            variant="primary"
                            onClick={() => handleEditProduct(product.id)}
                          >
                            Editar
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={9} className="text-center">
                        Nenhum produto encontrado.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Tab>
            <Tab eventKey="detalhes" title="Detalhes com Estoque">
              <Table responsive="sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Descrição do Produto / SKU</th>
                    <th>Condição</th>
                    <th>Preço Original</th>
                    <th>Moeda</th>
                    <th>Link</th>
                    <th>Imagem</th>
                    <th>Quantidade Inicial</th>
                    <th>Quantidade Disponível</th>
                    <th>Quantidade Vendida</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredProducts.length > 0 ? (
                    filteredProducts.map((product, index) => (
                      <tr key={product.id}>
                        <td>{index + 1 + offset}</td>
                        <td>
                          {product.title + '| SKU : ' + product.seller_sku}
                        </td>
                        <td>{product.condition}</td>
                        <td>{formatCurrency(product.original_price ?? 0)}</td>
                        <td>{product.currency_id}</td>
                        <td>
                          <a
                            href={product.permalink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <button>Abrir Anúncio</button>
                          </a>
                        </td>
                        <td>
                          <img
                            src={product.thumbnail}
                            alt={product.title}
                            style={{ width: '50px' }}
                          />
                        </td>
                        <td>{product.initial_quantity}</td>
                        <td>{product.available_quantity}</td>
                        <td>{product.sold_quantity}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={10} className="text-center">
                        Nenhum produto encontrado.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Tab>
          </Tabs>
        </div>
      </div>

      {/* Modal de Edição do Produto */}
      <Modal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        size="lg"
        fullscreen
      >
        <Modal.Header closeButton>
          <Modal.Title>Editar Anúncio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editProductId && (
            <EditMeliProduct
              id={editProductId}
              onClose={handleCloseEditModal}
              onSaveSuccess={handleIntegrationCheck} // Passa a função handleIntegrationCheck como prop
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Seção de Paginação e Ações */}
      <div className="sticky-footer">
        <div className="row justify-content-center">
          <div className="col-md-2">
            <Button
              onClick={handlePreviousPage}
              variant="primary"
              disabled={loading || importing || offset === 0}
              className="w-100"
            >
              <FaArrowLeft /> Página Anterior
            </Button>
          </div>
          <div className="col-md-2">
            <Button
              onClick={() => setSelectedProducts([])} // Modificado
              variant="danger"
              className="w-100"
              disabled={selectedProducts.length === 0}
            >
              <FaTrash /> Limpar Seleção
            </Button>
          </div>
          <div className="col-md-2">
            <Button
              onClick={handleImportProducts}
              variant="success"
              className="w-100"
              disabled={selectedProducts.length === 0 || importing}
            >
              {importing ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <>
                  <FaCloudUploadAlt /> Importar Produtos
                </>
              )}
            </Button>
          </div>
          <div className="col-md-3">
            <Button
              onClick={handleImportProductsWithStock}
              variant="info"
              className="w-100"
              disabled={selectedProducts.length === 0 || importing}
            >
              {importing ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <>
                  <FaCloudUploadAlt /> Importar Produtos com Estoque
                </>
              )}
            </Button>
          </div>
          <div className="col-md-2">
            <Button
              onClick={handleNextPage}
              variant="primary"
              disabled={loading || importing || offset + limit >= totalProducts}
              className="w-100"
            >
              <FaArrowRight /> Próxima Página
            </Button>
          </div>
        </div>
      </div>

      {/* Seção de Detalhes do Produto Selecionado */}
      {selectedProductId && <ProductDetails productId={selectedProductId} />}

      {/* Progress Bar */}
      {importing && (
        <ProgressBar
          now={importProgress}
          label={`${importProgress}%`}
          className="mt-2 progress-bar"
        />
      )}

      {/* Erros de Importação */}
      {failedImports.length > 0 && (
        <div className="mt-4 alert alert-danger">
          <h5>Os seguintes produtos falharam na importação:</h5>
          <ul>
            {failedImports.map((product) => (
              <li key={product.id}>
                {product.title} (SKU: {product.seller_sku})
              </li>
            ))}
          </ul>
        </div>
      )}

      {duplicatedSkus.length > 0 && (
        <div className="mt-4 alert alert-warning">
          <h5>Os seguintes SKUs já existem e não foram importados:</h5>
          <ul>
            {duplicatedSkus.map((sku, index) => (
              <li key={index}>{sku}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MeliProductImporter;
