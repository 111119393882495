import React, { useState, useEffect } from 'react';
import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import {
  Modal,
  Button,
  Form,
  Table,
  Spinner,
  FormSelect,
  InputGroup,
  FormControl,
} from 'react-bootstrap';
import { Categoria, ApiResponseData } from '../../components/types';
import { FaPlus } from 'react-icons/fa';
import moment from 'moment';

const CategoriasPage: React.FC = () => {
  const [categorias, setCategorias] = useState<Categoria[]>([]);
  const [selectedCategoria, setSelectedCategoria] = useState<Categoria | null>(
    null
  );
  const [nomeCategoria, setNomeCategoria] = useState<string>('');
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(5);
  const [total, setTotal] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [categoriaToDelete, setCategoriaToDelete] = useState<Categoria | null>(
    null
  );

  const token = sessionStorage.getItem('authToken');

  useEffect(() => {
    fetchCategorias(currentPage);
  }, [currentPage, perPage]);

  // Função para buscar todas as categorias
  const fetchCategorias = async (page: number, searchQuery: string = '') => {
    if (!token) {
      toast.error('Token não encontrado. Por favor, faça login novamente.');
      return;
    }

    setLoading(true);

    try {
      let url = `${
        import.meta.env.VITE_APP_API_URL
      }/categorias?page=${page}&per_page=${perPage}`;
      if (searchQuery) {
        url += `&search=${searchQuery}`;
      }

      const response: AxiosResponse<ApiResponseData<Categoria[]>> =
        await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

      if (response.data.meta) {
        setCategorias(response.data.data);
        setCurrentPage(response.data.meta.current_page);
        setTotalPages(response.data.meta.last_page);
        setPerPage(response.data.meta.per_page);
        setTotal(response.data.meta.total);
      } else {
        toast.error(
          'Erro ao buscar categorias: dados de paginação não encontrados.'
        );
      }
    } catch (error) {
      toast.error('Erro ao buscar categorias.');
    } finally {
      setLoading(false);
    }
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedCategoria(null);
    setNomeCategoria('');
    setIsEditing(false);
  };

  const handleEditCategoria = (categoria: Categoria) => {
    setSelectedCategoria(categoria);
    setNomeCategoria(categoria.nome);
    setIsEditing(true);
    setShowModal(true);
  };

  const handleSaveCategoria = async () => {
    if (!token) {
      toast.error('Token não encontrado. Por favor, faça login novamente.');
      return;
    }

    if (!nomeCategoria) {
      toast.error('O nome da categoria é obrigatório.');
      return;
    }

    setLoading(true);

    try {
      const categoriaData = {
        nome: nomeCategoria,
      };

      if (isEditing && selectedCategoria) {
        await axios.put(
          `${import.meta.env.VITE_APP_API_URL}/categorias/${
            selectedCategoria.id
          }`,
          categoriaData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        toast.success('Categoria atualizada com sucesso!');
      } else {
        await axios.post(
          `${import.meta.env.VITE_APP_API_URL}/categorias`,
          categoriaData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        toast.success('Categoria cadastrada com sucesso!');
      }

      handleCloseModal();
      fetchCategorias(currentPage);
    } catch (error) {
      toast.error('Erro ao salvar categoria.');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCategoria = async (categoria: Categoria) => {
    if (!token) {
      toast.error('Token não encontrado. Por favor, faça login novamente.');
      return;
    }

    setLoading(true);

    try {
      await axios.delete(
        `${import.meta.env.VITE_APP_API_URL}/categorias/${categoria.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success('Categoria excluída com sucesso!');
      fetchCategorias(currentPage);
    } catch (error) {
      toast.error('Erro ao excluir categoria.');
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmDelete = () => {
    if (categoriaToDelete) {
      handleDeleteCategoria(categoriaToDelete);
      setShowConfirmDeleteModal(false);
    }
  };

  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handlePerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSearchKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      fetchCategorias(1, search);
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
    let startPage = Math.max(1, currentPage - halfMaxPagesToShow);
    let endPage = Math.min(totalPages, currentPage + halfMaxPagesToShow);

    if (currentPage <= halfMaxPagesToShow) {
      endPage = Math.min(totalPages, maxPagesToShow);
    } else if (currentPage + halfMaxPagesToShow >= totalPages) {
      startPage = Math.max(1, totalPages - maxPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  return (
    <div className="container-fluid">
      <InputGroup className="mb-3">
        <FormControl
          placeholder="Pesquisar"
          aria-label="Pesquisar"
          value={search}
          onChange={handleSearchChange}
          onKeyDown={handleSearchKeyPress}
        />
      </InputGroup>
      <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
        <Button
          variant="primary"
          onClick={handleShowModal}
          className="mr-2 mb-2"
        >
          <FaPlus /> Nova Categoria
        </Button>
        <FormSelect
          value={perPage}
          onChange={handlePerPageChange}
          className="mb-2"
          style={{ width: '100px' }}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
        </FormSelect>
      </div>

      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">@</span>
          </Spinner>
        </div>
      ) : (
        <>
          <div className="table-responsive">
            <Table hover className="table-striped">
              <thead className="thead-dark">
                <tr>
                  <th>Nome</th>
                  <th>Categoria importada do Mercado Livre</th>
                  <th>Data de Criação</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {categorias.map((categoria) => (
                  <tr key={categoria.id}>
                    <td>{categoria.nome}</td>
                    <td>
                      {categoria.categoria_id
                        ? `Sim (${categoria.categoria_id})`
                        : 'Não'}
                    </td>
                    <td>
                      {moment(categoria.created_at).format(
                        'DD/MM/YYYY HH:mm:ss'
                      )}
                    </td>
                    <td>
                      <Button
                        variant="primary"
                        size="sm"
                        className="mr-2"
                        onClick={() => handleEditCategoria(categoria)}
                      >
                        Editar
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => {
                          setCategoriaToDelete(categoria);
                          setShowConfirmDeleteModal(true);
                        }}
                      >
                        Excluir
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <br />
          <p className="text-muted">
            Mostrando {categorias.length} de {total} categorias
          </p>
          <nav>
            <ul className="pagination justify-content-center">
              <li
                className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Anterior
                </button>
              </li>
              {getPageNumbers().map((pageNumber) => (
                <li
                  key={pageNumber}
                  className={`page-item ${
                    currentPage === pageNumber ? 'active' : ''
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(pageNumber)}
                  >
                    {pageNumber}
                  </button>
                </li>
              ))}
              <li
                className={`page-item ${
                  currentPage === totalPages ? 'disabled' : ''
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Próxima
                </button>
              </li>
            </ul>
          </nav>
        </>
      )}

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? 'Editar Categoria' : 'Cadastrar Nova Categoria'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Nome da Categoria</Form.Label>
              <Form.Control
                type="text"
                value={nomeCategoria}
                onChange={(e) => setNomeCategoria(e.target.value)}
                isInvalid={!nomeCategoria}
              />
              <Form.Control.Feedback type="invalid">
                Este campo é obrigatório.
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={handleSaveCategoria}
            disabled={loading}
          >
            {loading ? 'Salvando...' : 'Salvar'}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showConfirmDeleteModal}
        onHide={() => setShowConfirmDeleteModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tem certeza de que deseja excluir esta categoria?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowConfirmDeleteModal(false)}
          >
            Cancelar
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CategoriasPage;
