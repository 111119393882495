import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Modal, Button, Form } from 'react-bootstrap';
import { ApiResponseData, User } from '../../components/types';
import { refreshToken } from '../../components/Token/refreshToken';

interface UserWithPassword extends User {
  password?: string;
  confirmPassword?: string;
}

interface MeliAccount {
  id: string;
  nome: string;
  ativo: boolean;
}

const Usuario: React.FC = () => {
  const [user, setUser] = useState<UserWithPassword>({
    id: 0,
    name: '',
    email: '',
    email_verified_at: null,
    two_factor_confirmed_at: null,
    current_team_id: null,
    profile_photo_path: null,
    created_at: '',
    updated_at: '',
    password: '',
    confirmPassword: '',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [meliAccounts, setMeliAccounts] = useState<MeliAccount[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<string | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showUserModal, setShowUserModal] = useState<boolean>(false);
  const [meliUserInfo, setMeliUserInfo] = useState<any>(null);
  const authToken = sessionStorage.getItem('authToken');
  const userId = sessionStorage.getItem('userId');

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get<User>(
          `${import.meta.env.VITE_APP_API_URL}/users/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setUser(response.data); // Ajuste isso se a resposta é direta
      } catch (error) {
        console.error('Erro ao buscar usuário:', error);
        toast.error('Erro ao buscar dados do usuário.');
      }
    };

    fetchUser();
    fetchMeliAccounts();
  }, [authToken, userId]);

  const fetchMeliAccounts = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/meli-contas`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setMeliAccounts(response.data.data);
    } catch (error) {
      console.error('Erro ao buscar contas do Mercado Livre:', error);
      toast.error('Erro ao buscar contas do Mercado Livre');
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPasswordError(null);
    if (user.password !== user.confirmPassword) {
      setPasswordError('As senhas não coincidem.');
      return;
    }
    setLoading(true);
    try {
      const { confirmPassword, ...userData } = user;
      await axios.put<ApiResponseData<User>>(
        `${import.meta.env.VITE_APP_API_URL}/users/${user.id}`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast.success('Informações atualizadas com sucesso!');
    } catch (error) {
      console.error('Erro ao atualizar usuário:', error);
      toast.error('Erro ao atualizar informações.');
    } finally {
      setLoading(false);
    }
  };

  const handleIntegrationCheck = async () => {
    if (selectedAccount) {
      setLoading(true);
      try {
        await refreshToken(selectedAccount, setLoading); // Passa setLoading
        const token = sessionStorage.getItem('ml_access_token');
        const response = await axios.get(
          `https://api.mercadolibre.com/users/me`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setMeliUserInfo(response.data);
        setShowUserModal(true);
      } catch (error) {
        console.error('Erro ao verificar integração:', error);
        toast.error('Erro ao verificar integração.');
      } finally {
        setLoading(false);
        setShowModal(false);
      }
    } else {
      toast.error('Selecione uma conta do Mercado Livre.');
    }
  };

  return (
    <div className="container my-4">
      <h1 className="text-center mb-4">Atualizar Informações</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Nome
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            value={user.name}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={user.email}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Senha
          </label>
          <input
            type="password"
            className="form-control"
            id="password"
            name="password"
            value={user.password || ''}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="confirmPassword" className="form-label">
            Confirme a Senha
          </label>
          <input
            type="password"
            className="form-control"
            id="confirmPassword"
            name="confirmPassword"
            value={user.confirmPassword || ''}
            onChange={handleChange}
          />
        </div>
        {passwordError && <p className="text-danger">{passwordError}</p>}
        <button type="submit" className="btn btn-primary" disabled={loading}>
          {loading ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            'Atualizar'
          )}
        </button>
      </form>
      <div className="text-center mt-4">
        <Button onClick={() => setShowModal(true)}>Verificar Integração</Button>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Selecione a Conta do Mercado Livre</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Conta do Mercado Livre</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => setSelectedAccount(e.target.value)}
            >
              <option value="">Selecione uma conta</option>
              {meliAccounts.map((account) => (
                <option key={account.id} value={account.id}>
                  {account.nome} {account.ativo ? '(Ativo)' : ''}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={handleIntegrationCheck}
            disabled={loading}
          >
            {loading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              'Verificar'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showUserModal} onHide={() => setShowUserModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Informações do Usuário do Mercado Livre</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {meliUserInfo ? (
            <div>
              <p>
                <strong>ID:</strong> {meliUserInfo.id}
              </p>
              <p>
                <strong>Nickname:</strong> {meliUserInfo.nickname}
              </p>
              <p>
                <strong>Email:</strong> {meliUserInfo.email}
              </p>
              <p>
                <strong>Primeiro Nome:</strong> {meliUserInfo.first_name}
              </p>
              <p>
                <strong>Último Nome:</strong> {meliUserInfo.last_name}
              </p>
            </div>
          ) : (
            <p>Não foi possível obter as informações do usuário.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUserModal(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Usuario;
