import React, { useEffect, useRef } from 'react';
import JsBarcode from 'jsbarcode';

interface BarcodeCanvasProps {
  value: string;
}

const BarcodeCanvas: React.FC<BarcodeCanvasProps> = ({ value }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef.current) {
      JsBarcode(canvasRef.current, value, { format: 'CODE128' });
    }
  }, [value]);

  return <canvas ref={canvasRef} id={`barcode-${value}`} />;
};

export default BarcodeCanvas;
