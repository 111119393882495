import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Spinner,
  Table,
  Alert,
  Badge,
  Image,
  ListGroup,
  Row,
  Col,
  Container,
} from 'react-bootstrap';

interface ProductDetailsProps {
  productId: string;
}

const ProductDetails: React.FC<ProductDetailsProps> = ({ productId }) => {
  const [product, setProduct] = useState<any>(null);
  const [description, setDescription] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [mainImage, setMainImage] = useState<string | null>(null); // Estado para a imagem principal
  const token = sessionStorage.getItem('ml_access_token');

  useEffect(() => {
    const fetchProductDetails = async () => {
      setLoading(true);
      setError(null);
      try {
        // Requisição para os detalhes do produto
        const response = await axios.get(
          `https://api.mercadolibre.com/items/${productId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Requisição para o nome da categoria
        const categoryResponse = await axios.get(
          `https://api.mercadolibre.com/categories/${response.data.category_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Requisição para a descrição do produto
        const descriptionResponse = await axios.get(
          `https://api.mercadolibre.com/items/${productId}/description`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const productData = {
          ...response.data,
          category_name: categoryResponse.data.name,
        };
        setProduct(productData);
        setDescription(descriptionResponse.data.plain_text);
        setMainImage(productData.pictures[0]?.url || 'placeholder.jpg'); // Define a imagem principal
      } catch (error) {
        console.error('Erro ao buscar detalhes do produto:', error);
        setError('Erro ao carregar os detalhes do produto.');
      } finally {
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [productId]);

  const translateStatus = (status: string) => {
    const translations: { [key: string]: string } = {
      active: 'Ativo',
      paused: 'Pausado',
      closed: 'Fechado',
      under_review: 'Em Revisão',
      inactive: 'Inativo',
    };
    return translations[status] || status;
  };

  const translateCondition = (condition: string) => {
    const translations: { [key: string]: string } = {
      new: 'Novo',
      used: 'Usado',
    };
    return translations[condition] || condition;
  };

  const translateTags = (tag: string) => {
    const translations: { [key: string]: string } = {
      good_quality_thumbnail: 'Miniatura de boa qualidade',
      extended_warranty_eligible: 'Elegível para garantia estendida',
      immediate_payment: 'Pagamento imediato',
      cart_eligible: 'Elegível para carrinho',
    };
    return translations[tag] || tag;
  };

  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status">
          <span className="sr-only">...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  if (!product) {
    return null;
  }

  const renderAttributes = () => {
    return product.attributes.map((attribute: any) => (
      <tr key={attribute.id}>
        <td>
          <strong>{attribute.name}:</strong>
        </td>
        <td>{attribute.value_name}</td>
      </tr>
    ));
  };

  return (
    <Container style={{ marginTop: '20px', marginBottom: '20px' }}>
      <h3 className="text-center mb-4">Detalhes do Produto</h3>
      <Row className="mb-4">
        <Col md={4} className="text-center">
          <Image
            src={mainImage ?? ''} // Usa a imagem principal do estado, se for nulo, usa uma string vazia
            alt={product.title}
            fluid
            rounded
            style={{ maxWidth: '100%', marginBottom: '10px' }}
          />
          <ListGroup horizontal className="justify-content-center">
            {product.pictures.map((picture: any) => (
              <ListGroup.Item key={picture.id} className="p-1">
                <Image
                  src={picture.url}
                  alt={product.title}
                  thumbnail
                  style={{ width: '50px', cursor: 'pointer' }}
                  onClick={() => setMainImage(picture.url)} // Atualiza a imagem principal ao clicar
                />
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
        <Col md={8}>
          <Table borderless>
            <tbody>
              <tr>
                <td>
                  <strong>ID do Produto:</strong>
                </td>
                <td>{product.id}</td>
              </tr>
              <tr>
                <td>
                  <strong>Título:</strong>
                </td>
                <td>{product.title}</td>
              </tr>
              <tr>
                <td>
                  <strong>SKU:</strong>
                </td>
                <td>
                  {product.attributes.find(
                    (attr: any) => attr.id === 'SELLER_SKU'
                  )?.value_name || 'N/A'}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Preço:</strong>
                </td>
                <td>R$ {product.price.toFixed(2)}</td>
              </tr>
              <tr>
                <td>
                  <strong>Preço Original:</strong>
                </td>
                <td>
                  R${' '}
                  {product.original_price
                    ? product.original_price.toFixed(2)
                    : 'N/A'}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Moeda:</strong>
                </td>
                <td>{product.currency_id}</td>
              </tr>
              <tr>
                <td>
                  <strong>Quantidade Inicial:</strong>
                </td>
                <td>{product.initial_quantity}</td>
              </tr>
              <tr>
                <td>
                  <strong>Quantidade Disponível:</strong>
                </td>
                <td>{product.available_quantity}</td>
              </tr>
              <tr>
                <td>
                  <strong>Quantidade Vendida:</strong>
                </td>
                <td>{product.sold_quantity}</td>
              </tr>
              <tr>
                <td>
                  <strong>Condição:</strong>
                </td>
                <td>{translateCondition(product.condition)}</td>
              </tr>
              <tr>
                <td>
                  <strong>Status:</strong>
                </td>
                <td>{translateStatus(product.status)}</td>
              </tr>
              <tr>
                <td>
                  <strong>Substatus:</strong>
                </td>
                <td>{product.sub_status?.join(', ') || 'Nenhum'}</td>
              </tr>
              <tr>
                <td>
                  <strong>Categoria:</strong>
                </td>
                <td>{product.category_name}</td>
              </tr>
              <tr>
                <td>
                  <strong>Permalink:</strong>
                </td>
                <td>
                  <a
                    href={product.permalink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Abrir no Mercado Livre
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Garantia:</strong>
                </td>
                <td>{product.warranty || 'Nenhuma'}</td>
              </tr>
              <tr>
                <td>
                  <strong>Descrição:</strong> {/* Exibe a descrição */}
                </td>
                <td>{description || 'Descrição não disponível'}</td>
              </tr>
              <tr>
                <td>
                  <strong>Atributos:</strong>
                </td>
                <td>
                  <Table size="sm" bordered>
                    <tbody>{renderAttributes()}</tbody>
                  </Table>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Logística:</strong>
                </td>
                <td>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <strong>Modo:</strong> {product.shipping.mode}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>Frete Grátis:</strong>{' '}
                      {product.shipping.free_shipping ? 'Sim' : 'Não'}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>Tipo de Logística:</strong>{' '}
                      {product.shipping.logistic_type}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>Local de Retirada:</strong>{' '}
                      {product.shipping.local_pick_up ? 'Sim' : 'Não'}
                    </ListGroup.Item>
                  </ListGroup>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Dimensões:</strong>
                </td>
                <td>{product.shipping.dimensions || 'Não especificado'}</td>
              </tr>
              <tr>
                <td>
                  <strong>Tags:</strong>
                </td>
                <td>
                  {product.tags.map((tag: string) => (
                    <Badge key={tag} bg="info" className="mr-1">
                      {translateTags(tag)}
                    </Badge>
                  ))}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Endereço do Vendedor:</strong>
                </td>
                <td>
                  {product.seller_address ? (
                    <>
                      {product.seller_address.address_line},{' '}
                      {product.seller_address.city.name},{' '}
                      {product.seller_address.state.name},{' '}
                      {product.seller_address.zip_code}
                    </>
                  ) : (
                    'Endereço não disponível'
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Geolocalização:</strong>
                </td>
                <td>
                  {product.geolocation ? (
                    <ul style={{ paddingLeft: '20px' }}>
                      <li>
                        <strong>Latitude:</strong>{' '}
                        {product.geolocation.latitude}
                      </li>
                      <li>
                        <strong>Longitude:</strong>{' '}
                        {product.geolocation.longitude}
                      </li>
                    </ul>
                  ) : (
                    'Geolocalização não disponível'
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Criado em:</strong>
                </td>
                <td>{new Date(product.date_created).toLocaleString()}</td>
              </tr>
              <tr>
                <td>
                  <strong>Última Atualização:</strong>
                </td>
                <td>{new Date(product.last_updated).toLocaleString()}</td>
              </tr>
              <tr>
                <td>
                  <strong>Canal de Venda:</strong>
                </td>
                <td>{product.channels?.join(', ') || 'Nenhum'}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductDetails;
