import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Table, Form, Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

interface Screen {
  id: number;
  name: string;
}

interface ScreenPermission {
  screenId: number;
  permissions: string[];
}

const PermissionPage: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const navigate = useNavigate();
  const [screens, setScreens] = useState<Screen[]>([]);
  const [screenPermissions, setScreenPermissions] = useState<
    ScreenPermission[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    fetchScreens();
  }, []);

  const fetchScreens = async () => {
    try {
      const token = sessionStorage.getItem('authToken');
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/screens`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setScreens(response.data.screens);
      fetchPermissionsForAllScreens(response.data.screens);
    } catch (error) {
      console.error('Erro ao buscar telas:', error);
      toast.error('Erro ao carregar as telas.');
    }
  };

  const fetchPermissionsForAllScreens = async (screens: Screen[]) => {
    try {
      const token = sessionStorage.getItem('authToken');
      const permissionsPromises = screens.map((screen) =>
        axios.get(
          `${import.meta.env.VITE_APP_API_URL}/screens/${
            screen.id
          }/permissions/${userId}`,
          { headers: { Authorization: `Bearer ${token}` } }
        )
      );
      const permissionsResponses = await Promise.all(permissionsPromises);
      const screenPermissions = permissionsResponses.map((response) => ({
        screenId: response.data.screen_id,
        permissions: response.data.permissions,
      }));
      setScreenPermissions(screenPermissions);
    } catch (error) {
      console.error('Erro ao buscar permissões:', error);
      toast.error('Erro ao carregar as permissões.');
    } finally {
      setLoading(false);
    }
  };

  const handlePermissionChange = (screenId: number, permission: string) => {
    setScreenPermissions((prevPermissions) =>
      prevPermissions.map((screenPerm) =>
        screenPerm.screenId === screenId
          ? {
              ...screenPerm,
              permissions: screenPerm.permissions.includes(permission)
                ? screenPerm.permissions.filter((p) => p !== permission)
                : [...screenPerm.permissions, permission],
            }
          : screenPerm
      )
    );
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      const token = sessionStorage.getItem('authToken');
      const savePromises = screenPermissions.map((screenPerm) =>
        axios.put(
          `${import.meta.env.VITE_APP_API_URL}/screens/${
            screenPerm.screenId
          }/permissions`,
          {
            user_id: userId,
            permissions: screenPerm.permissions,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
      );
      await Promise.all(savePromises);
      toast.success('Permissões salvas com sucesso!');
      navigate('/admin');
    } catch (error) {
      console.error('Erro ao salvar permissões:', error);
      toast.error('Erro ao salvar as permissões.');
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return <Spinner animation="border" />;
  }

  return (
    <div className="container mt-4">
      <h1>Permissões do Usuário</h1>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Tela</th>
            <th>Visualizar</th>
            <th>Criar</th>
            <th>Editar</th>
            <th>Excluir</th>
          </tr>
        </thead>
        <tbody>
          {screens.map((screen) => {
            const screenPerm = screenPermissions.find(
              (sp) => sp.screenId === screen.id
            );
            return (
              <tr key={screen.id}>
                <td>{screen.name}</td>
                {['view', 'create', 'edit', 'delete'].map((perm) => (
                  <td key={perm}>
                    <Form.Check
                      type="checkbox"
                      checked={screenPerm?.permissions.includes(perm) || false}
                      onChange={() => handlePermissionChange(screen.id, perm)}
                    />
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Button variant="primary" onClick={handleSave} disabled={saving}>
        {saving ? 'Salvando...' : 'Salvar Permissões'}
      </Button>
    </div>
  );
};

export default PermissionPage;
