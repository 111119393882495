import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Sale } from './SalesTable';

interface SalesFormProps {
  sale?: Sale;
  onSave: (sale: Sale) => void;
}

const SalesForm: React.FC<SalesFormProps> = ({ sale, onSave }) => {
  const [formData, setFormData] = useState<Sale>({
    id: sale?.id || '',
    id_venda: sale?.id_venda || '',
    date_created: sale?.date_created || '',
    buyer_nickname: sale?.buyer_nickname || '',
    item_title: sale?.item_title || '',
    seller_sku: sale?.seller_sku || '',
    quantity: sale?.quantity || 1,
    unit_price: sale?.unit_price || 0,
    full_unit_price: sale?.full_unit_price || 0,
    total_paid_amount: sale?.total_paid_amount || 0,
    sale_fee: sale?.sale_fee || 0,
    shipping_cost: sale?.shipping_cost || 0,
    listing_type: sale?.listing_type || '',
    delivery_status: sale?.delivery_status || '',
    total: sale?.total || 0,
    receiver_name: sale?.receiver_name || '',
    address_line: sale?.address_line || '',
    city: sale?.city || '',
    state: sale?.state || '',
    zip_code: sale?.zip_code || '',
    receiver_phone: sale?.receiver_phone || '',
    meli_conta_id: sale?.meli_conta_id || '',
  });

  useEffect(() => {
    setFormData({
      id: sale?.id || '',
      id_venda: sale?.id_venda || '',
      date_created: sale?.date_created || '',
      buyer_nickname: sale?.buyer_nickname || '',
      item_title: sale?.item_title || '',
      seller_sku: sale?.seller_sku || '',
      quantity: sale?.quantity || 1,
      unit_price: sale?.unit_price || 0,
      full_unit_price: sale?.full_unit_price || 0,
      total_paid_amount: sale?.total_paid_amount || 0,
      sale_fee: sale?.sale_fee || 0,
      shipping_cost: sale?.shipping_cost || 0,
      listing_type: sale?.listing_type || '',
      delivery_status: sale?.delivery_status || '',
      total: sale?.total || 0,
      receiver_name: sale?.receiver_name || '',
      address_line: sale?.address_line || '',
      city: sale?.city || '',
      state: sale?.state || '',
      zip_code: sale?.zip_code || '',
      receiver_phone: sale?.receiver_phone || '',
      meli_conta_id: sale?.meli_conta_id || '',
    });
  }, [sale]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <Form id="sales-form" onSubmit={handleSubmit}>
      <Form.Group controlId="formIdVenda">
                        <Form.Label>ID da Venda</Form.Label>
                        <Form.Control
                            type="text"
                            name="id_venda"
                            value={formData.id_venda}
                            onChange={handleChange}
                            readOnly={!!sale} // Apenas leitura se estiver editando
                        />
                    </Form.Group>
                    <Form.Group controlId="formMeliContaId">
                        <Form.Label>ID da Conta Mercado Livre</Form.Label>
                        <Form.Control
                            type="text"
                            name="meli_conta_id"
                            value={formData.meli_conta_id}
                            onChange={handleChange}
                            readOnly={!!sale} // Apenas leitura se estiver editando
                        />
                    </Form.Group>
                    <Form.Group controlId="formDateCreated">
                        <Form.Label>Data de Criação</Form.Label>
                        <Form.Control
                            type="datetime-local"
                            name="date_created"
                            value={formData.date_created}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBuyerNickname">
                        <Form.Label>Apelido do Comprador</Form.Label>
                        <Form.Control
                            type="text"
                            name="buyer_nickname"
                            value={formData.buyer_nickname}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formItemTitle">
                        <Form.Label>Título do Item</Form.Label>
                        <Form.Control
                            type="text"
                            name="item_title"
                            value={formData.item_title}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formSellerSku">
                        <Form.Label>SKU do Vendedor</Form.Label>
                        <Form.Control
                            type="text"
                            name="seller_sku"
                            value={formData.seller_sku}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formQuantity">
                        <Form.Label>Quantidade</Form.Label>
                        <Form.Control
                            type="number"
                            name="quantity"
                            value={formData.quantity}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formUnitPrice">
                        <Form.Label>Preço Unitário</Form.Label>
                        <Form.Control
                            type="number"
                            name="unit_price"
                            value={formData.unit_price}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formFullUnitPrice">
                        <Form.Label>Preço Total do Item</Form.Label>
                        <Form.Control
                            type="number"
                            name="full_unit_price"
                            value={formData.full_unit_price}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formTotalPaidAmount">
                        <Form.Label>Valor Total Pago</Form.Label>
                        <Form.Control
                            type="number"
                            name="total_paid_amount"
                            value={formData.total_paid_amount}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formSaleFee">
                        <Form.Label>Tarifa de Venda</Form.Label>
                        <Form.Control
                            type="number"
                            name="sale_fee"
                            value={formData.sale_fee}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formShippingCost">
                        <Form.Label>Tarifa de Envio</Form.Label>
                        <Form.Control
                            type="number"
                            name="shipping_cost"
                            value={formData.shipping_cost}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formListingType">
                        <Form.Label>Tipo de Anúncio</Form.Label>
                        <Form.Control
                            type="text"
                            name="listing_type"
                            value={formData.listing_type}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formDeliveryStatus">
                        <Form.Label>Status de Entrega</Form.Label>
                        <Form.Control
                            type="text"
                            name="delivery_status"
                            value={formData.delivery_status}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formTotal">
                        <Form.Label>Total</Form.Label>
                        <Form.Control
                            type="number"
                            name="total"
                            value={formData.total}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formReceiverName">
                        <Form.Label>Nome do Recebedor</Form.Label>
                        <Form.Control
                            type="text"
                            name="receiver_name"
                            value={formData.receiver_name}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formAddressLine">
                        <Form.Label>Endereço</Form.Label>
                        <Form.Control
                            type="text"
                            name="address_line"
                            value={formData.address_line}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formCity">
                        <Form.Label>Cidade</Form.Label>
                        <Form.Control
                            type="text"
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formState">
                        <Form.Label>Estado</Form.Label>
                        <Form.Control
                            type="text"
                            name="state"
                            value={formData.state}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formZipCode">
                        <Form.Label>CEP</Form.Label>
                        <Form.Control
                            type="text"
                            name="zip_code"
                            value={formData.zip_code}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formReceiverPhone">
                        <Form.Label>Telefone</Form.Label>
                        <Form.Control
                            type="text"
                            name="receiver_phone"
                            value={formData.receiver_phone}
                            onChange={handleChange}
                        />
                    </Form.Group>
    </Form>
  );
};

export default SalesForm;
